import { lazy } from "react";

// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";

// --- Admin Panel Routes

//Users
const Users = Loadable(
  lazy(() => import("views/pages/admin/users/AdminUsers"))
);

const DashboardAnalytics = Loadable(
  lazy(() => import("views/dashboard/Analytics"))
);

const AdminDashboard = Loadable(
  lazy(() => import("views/pages/admin/dashboard"))
);
const AdminCountry = Loadable(lazy(() => import("views/pages/admin/country")));
const SubscriptionSales = Loadable(
  lazy(() => import("views/pages/admin/subscriptionSales"))
);
const AdminCategoirs = Loadable(
  lazy(() => import("views/pages/admin/categories"))
);
const TermsAndCondition = Loadable(
  lazy(() => import("views/pages/admin/categories/termsAndCondition"))
);
const PolicyPage = Loadable(
  lazy(() => import("views/pages/admin/categories/policyPage"))
);

const CreateCategory = Loadable(
  lazy(() => import("views/pages/categories/createCategory"))
);
const CreateSubCategory = Loadable(
  lazy(() => import("views/pages/categories/createSubCategory"))
);
const ChooseIcon = Loadable(
  lazy(() => import("views/pages/categories/chooseCategoryIcon"))
);
const CategoryCreatedSuccessfully = Loadable(
  lazy(() => import("views/pages/categories/categoryCreatedSuccessfully"))
);

const EditCategory = Loadable(
  lazy(() => import("views/pages/categories/editCategory"))
);

// ==============================|| MAIN ROUTING ||============================== //

const AdminnRoutes = {
  path: "/",
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: "/admin/dashboard",
      element: <AdminDashboard />,
    },

    {
      path: "/dashboard/analytics",
      element: <DashboardAnalytics />,
    },

    {
      path: "/admin/users",
      element: <Users />,
    },
    {
      path: "/admin/country",
      element: <AdminCountry />,
    },
    {
      path: "/admin/subscription-sales",
      element: <SubscriptionSales />,
    },
    {
      path: "admin/terms",
      element: <TermsAndCondition />,
    },
    {
      path: "/admin/policy",
      element: <PolicyPage />,
    },
    {
      path: "/admin/categories",
      element: <AdminCategoirs />,
    },
    {
      path: "/create-category",
      element: <CreateCategory />,
    },

    {
      path: "/edit-category",
      element: <EditCategory />,
    },

    {
      path: "/create-sub-category",
      element: <CreateSubCategory />,
    },

    {
      path: "/choose-icons",
      element: <ChooseIcon />,
    },
    {
      path: "/category-created-succesfully",
      element: <CategoryCreatedSuccessfully />,
    },
  ],
};

export default AdminnRoutes;
