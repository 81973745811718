import API from "../../../utilities/API";

export const getTransactionList = async (queryParams = {}) => {
  let url = `/api/v1/transaction`;
  if (Object.keys(queryParams).length > 0) {
    url +=
      "?" +
      Object.entries(queryParams)
        .filter(([, value]) => value !== undefined && value !== null)
        .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
        .join("&");
  }

  const response = await API.get(
    `${url}${Object.keys(queryParams).length > 0 ? `&` : `?`}`
  );

  return response.data;
};

export const getTransactionBrief = async (fromDate, toDate) => {
  let url = `/api/v1/transaction/brief?fromDate=${fromDate}&toDate=${toDate}`;
  const response = await API.get(`${url}`);
  return response.data;
};

export const createTransaction = async (payload) => {
  const url = "/api/v1/transaction";
  const response = await API.post(url, payload);

  return response.data;
};

export const addCoverPayment = async (id, payload) => {
  const url = `/api/v1/transaction/coverPayment/${id}`;
  const response = await API.post(url, payload);

  return response.data;
};
export const deleteTransaction = async (id) => {
  const url = `/api/v1/transaction/${id}`;
  const response = await API.delete(url);

  return response.data;
};
