import { Box } from "@mui/material";
import React from "react";

const IconBox = ({ style, bgcolor, className, url, imgHeight, imgWidth }) => {
  return (
    <Box
      width="40px"
      height="40px"
      bgcolor={bgcolor ? bgcolor : "rgb(31, 34, 39, 0.75)"}
      borderRadius="50px"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <img
        width={imgWidth ? imgWidth : "35px"}
        height={imgHeight ? imgHeight : "35px"}
        style={{
          objectFit: "cover",
          // borderRadius: "100%",
          ...style,
        }}
        src={url ? url : ""}
        alt=""
        className={className}
      />
    </Box>
  );
};

export default IconBox;
