import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import { Typography } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",

  bgcolor: "#2b2b33",
  boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.08)",
  borderRadius: "26px",
  py: 3,
};

export default function TransitionsModal({
  show,
  onHide,
  size,
  children,
  isClodeIcon,
  ...props
}) {
  const INPUT_LABEL_PROPS = {
    variant: "h6",
    component: "h6",
    fontWeight: "500",
    spacing: "0.1px",
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={show}
      onClose={onHide}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={show}>
        <Box
          p="0px"
          sx={{
            width: "100%",
            maxWidth: "326px",
            ...style,
          }}
        >
          <Box mb="15px" display="flex" justifyContent="space-between" px={4}>
            {props?.tittle && (
              <Typography
                {...INPUT_LABEL_PROPS}
                htmlFor="lastName"
                gutterBottom={true}
              >
                {props.tittle}
              </Typography>
            )}
            {isClodeIcon && (
              <IconButton onClick={() => onHide()}>
                <CloseIcon />
              </IconButton>
            )}
          </Box>
          <Box maxHeight="500px" px={2} overflow="auto">
            {children}
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}

TransitionsModal.defaultProps = {
  size: "md",
};
