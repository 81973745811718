import { Button } from "@mui/material";

export default function ButtonComponent({ children, ...props }) {
  return (
    <Button
      {...props}
      style={{
        color: props.color,
        textTransform: "capitalize",
        width: props.width,
        background: props.bg,
        height: props.height,
        borderRadius: props.radius,
      }}
      
    >
      {children}
    </Button>
  );
}

ButtonComponent.propTypes = {};

ButtonComponent.defaultProps = {
  variant: "contained",
};
