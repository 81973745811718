// third-party
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

// project imports
import snackbarReducer from "./slices/snackbar";
import customerReducer from "./slices/customer";
import contactReducer from "./slices/contact";
import productReducer from "./slices/product";
import chatReducer from "./slices/chat";
import calendarReducer from "./slices/calendar";
import mailReducer from "./slices/mail";
import userReducer from "./slices/user";
import cartReducer from "./slices/cart";
import kanbanReducer from "./slices/kanban";
import menuReducer from "./slices/menu";
import authReducer from "./auth/authSlice/auth.slice";
import galleryReducer from "./gallery/GallerySlice/gallery.slice";
import lookupReducer from "./lookups/LookupsSlice/lookups.slice";
import walletReducer from "./wallets/walletsSlice/wallets.slice";
import accountReducer from "./accounts/accountsSlice/accounts.slice";
import loanReducer from "./loan/loanSlice/loan.slice";
import goalReducer from "./goal/goalSlice/goal.slice";
import creditCardReducer from "./creditCard/creditCardSlice/credit.slice";
import debitCardReducer from "./debitCard/debitCardSlice/debit.slice";
import categoriesReducer from "./categories/categoriesSlice/categories.slice";
import paymentReducer from "./payments/paymentsSlice/payments.slice";
import transactionReducer from "./transaction/transactionSlice/transaction.slice";
import reserveReducer from "./reserve/reserveSlice/reserve.slice";
import myReportsReducer from "./myReports/reportsSlice/reports.slice";
import notificationReducer from "./notification/notificationSlice/notifications.slice";
import adminReducer from "./admin/adminSlice/admin.slice";

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  auth: authReducer,
  snackbar: snackbarReducer,
  cart: persistReducer(
    {
      key: "cart",
      storage,
      keyPrefix: "berry-",
    },
    cartReducer
  ),
  kanban: kanbanReducer,
  customer: customerReducer,
  contact: contactReducer,
  product: productReducer,
  chat: chatReducer,
  calendar: calendarReducer,
  mail: mailReducer,
  user: userReducer,
  menu: menuReducer,
  gallery: galleryReducer,
  lookups: lookupReducer,
  wallets: walletReducer,
  account: accountReducer,
  loan: loanReducer,
  goal: goalReducer,
  creditCard: creditCardReducer,
  debitCard: debitCardReducer,
  categories: categoriesReducer,
  payment: paymentReducer,
  transaction: transactionReducer,
  reserve: reserveReducer,
  myReports: myReportsReducer,
  notification: notificationReducer,
  admin: adminReducer,
});

export default reducer;
