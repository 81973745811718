import API from "../../../utilities/API";

export const getUserList = async (offset, limit, queryParams = {}) => {
  let url = `/api/v1/user`;
  if (Object.keys(queryParams).length > 0) {
    url +=
      "?" +
      Object.entries(queryParams)
        .filter(([, value]) => value !== undefined && value !== null)
        .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
        .join("&");
  }

  const response = await API.get(
    `${url}${
      Object.keys(queryParams).length > 0 ? `&` : `?`
    }offset=${offset}&limit=${limit}`
  );

  return response.data;
};

export const getUserByCountry = async () => {
  const url = `/api/v1/user/byCountry`;
  const response = await API.get(url);

  return response.data;
};

export const getTransactionDetails = async (queryParams) => {
  // const url = `/api/v1/dashboard/transactionDetails`;
  // const response = await API.get(url);

  let url = `/api/v1/dashboard/transactionDetails`;
  if (Object.keys(queryParams).length > 0) {
    url +=
      "?" +
      Object.entries(queryParams)
        .filter(([, value]) => value !== undefined && value !== null)
        .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
        .join("&");
  }

  const response = await API.get(
    `${url}${Object.keys(queryParams).length > 0 ? `&` : `?`}`
  );

  return response.data;
};

export const getTransactionGraphData = async (queryParams) => {
  // const url = `/api/v1/dashboard/transactionGraphData`;
  // const response = await API.get(url);

  // return response.data;

  let url = `/api/v1/dashboard/transactionGraphData`;
  if (Object.keys(queryParams).length > 0) {
    url +=
      "?" +
      Object.entries(queryParams)
        .filter(([, value]) => value !== undefined && value !== null)
        .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
        .join("&");
  }

  const response = await API.get(
    `${url}${Object.keys(queryParams).length > 0 ? `&` : `?`}`
  );

  return response.data;
};

export const getWalletDetails = async () => {
  const url = `/api/v1/dashboard/AverageWalletDetails`;
  const response = await API.get(url);

  return response.data;
};

export const createPolicy = async (payload) => {
  const url = "/api/v1/policy";
  const response = await API.post(url, payload);
  return response.data;
};

export const updatePolicy = async (policyId, updatePayload) => {
  const url = `/api/v1/policy/${policyId}`;
  const response = await API.put(url, updatePayload);

  return response.data;
};

export const deletePolicy = async (policyId) => {
  const url = `/api/v1/policy/${policyId}`;
  const response = await API.delete(url);

  return response.data;
};

export const getPolicyDetails = async (policyId) => {
  let url;
  if (policyId) {
    url = `/api/v1/policy/${policyId && policyId}`;
  } else {
    url = `/api/v1/policy`;
  }
  const response = await API.get(url);

  return response.data;
};
