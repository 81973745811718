import PropTypes from "prop-types";
import { createContext, useEffect, useReducer } from "react";

// third-party
import { Chance } from "chance";
import jwtDecode from "jwt-decode";

// reducer - state management
import { LOGIN, LOGOUT } from "store/actions";
import accountReducer from "store/accountReducer";

// project imports
import Loader from "ui-component/Loader";
import axios from "utils/axios";
import { setUser, logoutSlice } from "store/auth/authSlice/auth.slice";
import { useDispatch, useSelector } from "react-redux";

const chance = new Chance();

// constant
const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null,
};

const verifyToken = (serviceToken) => {
  if (!serviceToken) {
    return false;
  }
  const decoded = jwtDecode(serviceToken);
  /**
   * Property 'exp' does not exist on type '<T = unknown>(token, options?: JwtDecodeOptions | undefined) => T'.
   */
  return decoded.exp > Date.now() / 1000;
};

const setSession = (serviceToken) => {
  if (serviceToken) {
    localStorage.setItem("serviceToken", serviceToken);
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
  } else {
    localStorage.removeItem("serviceToken");
    delete axios.defaults.headers.common.Authorization;
  }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
  // const [state, dispatch] = useReducer(accountReducer, initialState);
  const dispatch = useDispatch();
  let {
    isLoading,
    isSuccess,
    isError,
    message,
    user,
    isLoggedIn,
    isTwoFactor,
  } = useSelector((state) => state.auth);
  console.log("user:<<<<<<<<<", user);
  useEffect(() => {
    const init = async () => {
      try {
        const serviceToken = window.localStorage.getItem("serviceToken");
        console.log(
          ">>>>>>>>>>>>>>>>>>>>>",
          window.localStorage.getItem("my_finance_user")
        );
        let userDetailsInString =
          window.localStorage.getItem("my_finance_user");

        const userDetails = JSON.parse(userDetailsInString);
        console.log("userDetails", userDetails);
        if (serviceToken && userDetails) {
          setSession(serviceToken);

          dispatch(setUser(userDetails));
        } else {
          dispatch(logoutSlice());
        }
      } catch (err) {
        console.error(err);
        dispatch(logoutSlice());
      }
    };

    init();
  }, []);

  const login = async (email, password) => {
    const response = await axios.post("/api/account/login", {
      email,
      password,
    });
    const { serviceToken, user } = response.data;
    setSession(serviceToken);
    dispatch({
      type: LOGIN,
      payload: {
        isLoggedIn: true,
        user,
      },
    });
  };

  const register = async (email, password, firstName, lastName) => {
    // todo: this flow need to be recode as it not verified
    const id = chance.bb_pin();
    const response = await axios.post("/api/account/register", {
      id,
      email,
      password,
      firstName,
      lastName,
    });
    let users = response.data;

    if (
      window.localStorage.getItem("users") !== undefined &&
      window.localStorage.getItem("users") !== null
    ) {
      const localUsers = window.localStorage.getItem("users");
      users = [
        ...JSON.parse(localUsers),
        {
          id,
          email,
          password,
          name: `${firstName} ${lastName}`,
        },
      ];
    }

    window.localStorage.setItem("users", JSON.stringify(users));
  };

  const logout = () => {
    setSession(null);
    dispatch({ type: LOGOUT });
  };

  const resetPassword = async (email) => {
    console.log(email);
  };

  const updateProfile = () => {};

  // if (initialState.isInitialized !== undefined && !initialState.isInitialized) {
  //   return <Loader />;
  // }

  return (
    <JWTContext.Provider
      value={{
        // ...state,
        user,
        isLoggedIn,
        isTwoFactor,
        login,
        logout,
        register,
        resetPassword,
        updateProfile,
      }}
    >
      {children}
    </JWTContext.Provider>
  );
};

JWTProvider.propTypes = {
  children: PropTypes.node,
};

export default JWTContext;
