import { memo, useEffect } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import { Box, Divider, List, Typography, useMediaQuery } from "@mui/material";

// project imports
import NavItem from "./NavItem";
import menuItem from "menu-items";
import NavGroup from "./NavGroup";
import useConfig from "hooks/useConfig";
import { Menu } from "menu-items/widget";
import LAYOUT_CONST from "constant";
import { HORIZONTAL_MAX_ITEM } from "config";
import { useSelector } from "store";

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
  let my_finance_user_in_strng = localStorage.getItem("my_finance_user");
  let my_finance_user_in_Json = JSON.parse(my_finance_user_in_strng);

  let finalMenuItem =
    my_finance_user_in_Json?.role_id === 2
      ? menuItem.items
      : menuItem.adminItems;

  const theme = useTheme();
  const { layout } = useConfig();
  const { drawerOpen } = useSelector((state) => state.menu);
  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));

  const getMenu = Menu();
  const handlerMenuItem = () => {
    const isFound = finalMenuItem.some((element) => {
      if (element.id === "widget") {
        return true;
      }
      return false;
    });

    if (getMenu?.id !== undefined && !isFound) {
      finalMenuItem.splice(1, 0, getMenu);
    }
  };

  useEffect(() => {
    handlerMenuItem();
    // eslint-disable-next-line
  }, []);

  // last menu-item to show in horizontal menu bar
  const lastItem =
    layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && !matchDownMd
      ? HORIZONTAL_MAX_ITEM
      : null;

  let lastItemIndex = finalMenuItem.length - 1;
  let remItems = [];
  let lastItemId;

  if (lastItem && lastItem < finalMenuItem.length) {
    lastItemId = finalMenuItem[lastItem - 1].id;
    lastItemIndex = lastItem - 1;
    remItems = finalMenuItem
      .slice(lastItem - 1, finalMenuItem.length)
      .map((item) => ({
        title: item.title,
        elements: item.children,
        icon: item.icon,
        ...(item.url && {
          url: item.url,
        }),
      }));
  }

  const navItems = finalMenuItem.slice(0, lastItemIndex + 1).map((item) => {
    switch (item.type) {
      case "group":
        if (item.url && item.id !== lastItemId) {
          return (
            <List key={item.id}>
              <NavItem item={item} level={1} isParents />
              {layout !== LAYOUT_CONST.HORIZONTAL_LAYOUT && (
                <Divider sx={{ py: 0.5 }} />
              )}
            </List>
          );
        }
        return (
          <NavGroup
            key={item.id}
            item={item}
            lastItem={lastItem}
            remItems={remItems}
            lastItemId={lastItemId}
          />
        );
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return layout === LAYOUT_CONST.VERTICAL_LAYOUT ||
    (layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && matchDownMd) ? (
    <Box {...(drawerOpen && { sx: { mt: 1.5 } })}>{navItems}</Box>
  ) : (
    <>{navItems}</>
  );
};

export default memo(MenuList);
