import { Box, Typography } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useNavigate } from "react-router-dom";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
const CardTittle = ({ tittle, goTo }) => {
  const navigate = useNavigate();
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
      <Typography
        fontSize="18px"
        mb="0x"
        variant="h1"
        component="h2"
        color="#FFFFFF"
        fontWeight="500"
      >
        {tittle}
      </Typography>
      <Box
        bgcolor="rgb(31, 34, 39, 0.75)"
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="30px"
        height="30px"
        borderRadius="100px"
        onClick={() => navigate(goTo)}
      >
        <ChevronRightIcon />
      </Box>
    </Box>
  );
};

export default CardTittle;
