import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getNotificationsList,
  getAccountDetails,
  createAccount,
  updateAccount,
  deleteAccount,
  getCardTypeList,
  getCashAccountGraphDetails,
  getAccountTransactionDetails,
  updateNotification,
} from "../NotificationServices/notificationServices";

const initialState = {
  allNotifications: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const getNotificationsListSlice = createAsyncThunk(
  "notification/getNotificationsList",
  async ({ moveToNext, onFailure }, thunkAPI) => {
    try {
      const response = await getNotificationsList();
      if (moveToNext) {
        moveToNext(response);
      }
      return response?.data;
    } catch (error) {
      if (onFailure) {
        onFailure(error.message);
      }
      if (error?.message === "Request failed with status code 403") {
        localStorage.removeItem("serviceToken");
        localStorage.removeItem("my_finance_user");
        window.location.replace("/");
      }
      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const updateNotificationSlice = createAsyncThunk(
  "notification/updateNotification",
  async (
    { notificationId, updatePayload, moveToNext, onFailure },
    thunkAPI
  ) => {
    try {
      const response = await updateNotification(notificationId, updatePayload);
      if (moveToNext) {
        moveToNext(response);
      }
      return response;
    } catch (error) {
      if (onFailure) {
        onFailure(error.message);
      }

      if (error?.message === "Request failed with status code 403") {
        localStorage.removeItem("serviceToken");
        localStorage.removeItem("my_finance_user");
        window.location.replace("/");
      }

      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const accountSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isLoginSuccess = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getNotificationsListSlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
        state.message = "";
      })
      .addCase(getNotificationsListSlice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.allNotifications = action.payload;
        state.message = "";
      })
      .addCase(getNotificationsListSlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(updateNotificationSlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
        state.message = "";
      })
      .addCase(updateNotificationSlice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = "";
      })
      .addCase(updateNotificationSlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { setUser, reset, logoutSlice } = accountSlice.actions;

export default accountSlice.reducer;
