import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  cardTypeList,
  getCardNetworkList,
  getCategoryTypeList,
  getCountiresList,
  getCurrencyList,
  getGenderList,
  getPaymentFrequencyList,
  getStatusList,
  getTimeZoneList,
} from "../LookupsServices/lookupsServices";

const initialState = {
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",

  categoryListType: [],
  genderList: [],
  countiresList: [],
  cardNetworkList: [],
  cardTypeList: [],
  statusList: [],
  currencyList: [],
  timeZonesList: [],
};

export const getGenderListSlice = createAsyncThunk(
  "auth/getGenderListSlice",
  async ({ moveToNext, onFailure }, thunkAPI) => {
    console.log("runing.... gender list");
    try {
      const response = await getGenderList();
      if (moveToNext) {
        moveToNext(response);
      }
      return response;
    } catch (error) {
      if (onFailure) {
        onFailure(error.message);
      }
      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const getCountiresListSlice = createAsyncThunk(
  "auth/getCountiresListSlice",
  async ({ moveToNext, onFailure }, thunkAPI) => {
    try {
      const response = await getCountiresList();
      if (moveToNext) {
        moveToNext(response);
      }
      return response;
    } catch (error) {
      if (onFailure) {
        onFailure(error.message);
      }
      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const getCardNetworkListSlice = createAsyncThunk(
  "auth/getCardNetworkListSlice",
  async ({ moveToNext, onFailure }, thunkAPI) => {
    console.log("runing.....");
    try {
      const response = await getCardNetworkList();
      if (moveToNext) {
        moveToNext(response?.data);
      }
      console.log("response", response);
      return response?.data;
    } catch (error) {
      if (onFailure) {
        onFailure(error.message);
      }
      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const cardTypeListSlice = createAsyncThunk(
  "auth/cardTypeListSlice",
  async ({ moveToNext, onFailure }, thunkAPI) => {
    try {
      const response = await cardTypeList();
      if (moveToNext) {
        moveToNext(response);
      }
      return response;
    } catch (error) {
      if (onFailure) {
        onFailure(error.message);
      }
      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const getStatusListSlice = createAsyncThunk(
  "auth/getStatusListSlice",
  async ({ moveToNext, onFailure }, thunkAPI) => {
    try {
      const response = await getStatusList();
      if (moveToNext) {
        moveToNext(response);
      }
      return response;
    } catch (error) {
      if (onFailure) {
        onFailure(error.message);
      }
      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const getCurrencyListSlice = createAsyncThunk(
  "auth/getCurrencyListSlice",
  async ({ moveToNext, onFailure }, thunkAPI) => {
    try {
      const response = await getCurrencyList();
      if (moveToNext) {
        moveToNext(response);
      }
      return response?.data;
    } catch (error) {
      if (onFailure) {
        onFailure(error.message);
      }
      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const getPaymentFrequencySlice = createAsyncThunk(
  "auth/getPaymentFrequencySlice",
  async ({ moveToNext, onFailure }, thunkAPI) => {
    try {
      const response = await getPaymentFrequencyList();
      console.log("response>>>", response);
      if (moveToNext) {
        moveToNext(response?.data);
      }
      return response?.data;
    } catch (error) {
      if (onFailure) {
        onFailure(error.message);
      }
      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const getCategoryTypeListSlice = createAsyncThunk(
  "auth/getCategoryTypeListSlice",
  async ({ moveToNext, onFailure }, thunkAPI) => {
    try {
      const response = await getCategoryTypeList();
      console.log("response>>>", response);
      if (moveToNext) {
        moveToNext(response?.data);
      }
      return response?.data;
    } catch (error) {
      if (onFailure) {
        onFailure(error.message);
      }
      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const getTimeZoneListSlice = createAsyncThunk(
  "auth/getTimeZoneListSlice",
  async ({ moveToNext, onFailure }, thunkAPI) => {
    try {
      const response = await getTimeZoneList();
      if (moveToNext) {
        moveToNext(response?.data);
      }
      return response?.data;
    } catch (error) {
      if (onFailure) {
        onFailure(error.message);
      }
      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const lookupSlices = createSlice({
  name: "auth",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
      state.genderList = [];
      state.countiresList = [];
      state.cardNetworkList = [];
      state.cardTypeList = [];
      state.statusList = [];
      state.currencyList = [];
      state.getPaymentFrequency = [];
      state.timeZonesList = [];
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getGenderListSlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(getGenderListSlice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.genderList = action.payload;
      })
      .addCase(getGenderListSlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getCountiresListSlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(getCountiresListSlice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.countiresList = action.payload;
      })
      .addCase(getCountiresListSlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getCardNetworkListSlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(getCardNetworkListSlice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.cardNetworkList = action.payload;
      })
      .addCase(getCardNetworkListSlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(cardTypeListSlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(cardTypeListSlice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.cardTypeList = action.payload;
      })
      .addCase(cardTypeListSlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getStatusListSlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(getStatusListSlice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.statusList = action.payload;
      })
      .addCase(getStatusListSlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getCurrencyListSlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(getCurrencyListSlice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.currencyList = action.payload;
      })
      .addCase(getCurrencyListSlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getPaymentFrequencySlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(getPaymentFrequencySlice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.getPaymentFrequency = action.payload;
      })
      .addCase(getPaymentFrequencySlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getCategoryTypeListSlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(getCategoryTypeListSlice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.categoryListType = action.payload;
      })
      .addCase(getCategoryTypeListSlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getTimeZoneListSlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(getTimeZoneListSlice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.timeZonesList = action.payload;
      })
      .addCase(getTimeZoneListSlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { setUser, reset, logoutSlice } = lookupSlices.actions;

export default lookupSlices.reducer;
