// third-party
import { FormattedMessage } from "react-intl";

// assets
import {
  IconMoneybag,
  IconTargetArrow,
  IconBuildingBank,
  IconCashBanknote,
  IconDashboard,
  IconDeviceAnalytics,
  IconWallet,
  IconLayoutDashboard,
  IconCoin,
  IconHomeDollar,
  IconReportAnalytics,
  IconCrown,
  IconMenu2,
} from "@tabler/icons";

const icons = {
  IconDashboard,
  IconDeviceAnalytics,
  IconWallet,
  IconBuildingBank,
  IconCashBanknote,
  IconTargetArrow,
  IconMoneybag,
  IconLayoutDashboard,
  IconCoin,
  IconHomeDollar,
  IconReportAnalytics,
  IconCrown,
  IconMenu2,
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const dashboard = {
  id: "dashboard",
  title: " ",
  icon: icons.IconDashboard,
  type: "group",
  children: [
    {
      id: "dashboard",
      title: " MyDashboard",
      type: "item",
      url: "custom-dashboard",
      icon: icons.IconLayoutDashboard,
      breadcrumbs: true,
    },
    {
      id: "myTask",
      title: "MyTasks",
      type: "item",
      url: "notification-list",
      icon: icons.IconMenu2,
      breadcrumbs: false,
    },
    {
      id: "accounts",
      title: "MyAccounts",
      type: "item",
      url: "cash-accounts",
      icon: icons.IconCashBanknote,
      breadcrumbs: true,
    },
    {
      id: "goals",
      title: "MyGoals",
      type: "item",
      url: "saving-goal",
      icon: icons.IconTargetArrow,
      breadcrumbs: false,
    },
    {
      id: "debts",
      title: "MyDebts",
      type: "item",
      url: "debt-commingsoon",
      icon: icons.IconCoin,
      breadcrumbs: false,
    },
    // {
    //   id: "budget",
    //   title: "MyBudget",
    //   type: "item",
    //   url: "budget-dashbaord",
    //   icon: icons.IconBuildingBank,
    //   breadcrumbs: false,
    // },
    {
      id: "reserve",
      title: "MyReserve",
      type: "item",
      url: "my-reserve",
      icon: icons.IconHomeDollar,
      breadcrumbs: false,
    },
    {
      id: "reports",
      title: "MyStatistics",
      type: "item",
      url: "reports",
      icon: icons.IconReportAnalytics,
      breadcrumbs: false,
    },

    // {
    //   id: "my_subscription",
    //   title: "MySubscription",
    //   type: "item",
    //   url: "my-subscription",
    //   icon: icons.IconCrown,
    //   breadcrumbs: false,
    // },
  ],
};

export default dashboard;
