import moment from "moment";
import { toast } from "react-toastify";

function notify(data, condition) {
  condition === "error"
    ? toast.error(data, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    : toast.success(data, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
}

function notificationNotify(data, condition) {
  toast.success(data, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: false,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
    theme: "dark",
    className: "custom-notification-popup",
    // transition: Zoom,
  });
}
function getDateInYYYY_MM_DD(date) {
  const currentDate = new Date(date);
  const year = currentDate.getFullYear();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
  const day = currentDate.getDate().toString().padStart(2, "0");

  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
}

function formatDateInSimpleWord(inputDate) {
  const parsedDate = moment(inputDate);

  // Format the date in the "22 June 2023" format
  const formattedDate = parsedDate.format("D MMMM YYYY");
  return formattedDate;
}

const getInitialName = (name) => {
  let initials;
  const nameSplit = name.split(" ");
  const nameLength = nameSplit.length;
  if (nameLength > 1) {
    initials =
      nameSplit[0].substring(0, 1) + nameSplit[nameLength - 1].substring(0, 1);
  } else if (nameLength === 1) {
    initials = nameSplit[0].substring(0, 1);
  } else return;
  return initials.toUpperCase();
};

const createImageFromInitials = (size, name, color) => {
  if (name == null) return;
  name = getInitialName(name);
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  canvas.width = canvas.height = size;
  context.fillStyle = "#000";
  context.fillRect(0, 0, size, size);
  context.fillStyle = `${color}50`;
  context.fillRect(0, 0, size, size);
  context.fillStyle = color;
  context.textBaseline = "middle";
  context.textAlign = "center";
  context.font = `${size / 2}px Roboto`;
  context.fillText(name, size / 2, size / 2);
  return canvas.toDataURL();
};

function getFromDateToDate(filterOption) {
  const moment = require("moment"); // Assuming Moment.js is imported

  let fromDate, toDate;

  if (filterOption === "THIS_MONTH") {
    fromDate = moment().startOf("month");
    toDate = moment().endOf("month");
  } else if (filterOption === "THIS_YEAR") {
    fromDate = moment().startOf("year");
    toDate = moment().endOf("year");
  } else if (filterOption === "YEAR_TO_DATE") {
    fromDate = moment().startOf("year");
    toDate = moment(); // Use current date for "Year to date"
  } else if (filterOption === "TODAY") {
    fromDate = moment().startOf("day"); // Get start of the current day
    toDate = moment().endOf("day"); // Get end of the current day
  } else if (filterOption === "THIS_WEEK") {
    // Get the start and end of the current week (Sunday - Saturday)
    fromDate = moment().startOf("week");
    toDate = moment().endOf("week");
  } else {
    // Handle invalid filterOption if needed
    console.error(`Invalid filter option: ${filterOption}`);
    return null; // Or return appropriate default values
  }

  return { fromDate, toDate };
}

const getDayCount = () => {
  let my_finance_user_in_strng = localStorage.getItem("my_finance_user");
  let my_finance_user_in_Json = JSON.parse(my_finance_user_in_strng);
  let firstDayOfWeek = my_finance_user_in_Json?.first_day_of_week;

  switch (firstDayOfWeek) {
    case "monday":
      return 1;

    case "tuesday":
      return 2;

    case "wednesday":
      return 3;

    case "thursday":
      return 4;

    case "friday":
      return 5;

    case "saturday":
      return 6;

    case "sunday":
      return 7;

    default:
      break;
  }
};

function getPreviousFromToDate(interval) {
  let from_date, to_date;

  // Get today's date
  let today = moment();
  console.log("today", today);
  console.log("getDayCount", getDayCount());
  // Set Tuesday as the first day of the week
  moment.updateLocale("en", {
    week: {
      dow: getDayCount(),
    },
  });

  // Calculate the start and end dates based on the interval
  if (interval === "MONTHLY") {
    // Calculate the first day of the previous month
    from_date = moment(today).subtract(1, "months").startOf("month");
    // Calculate the last day of the previous month
    to_date = moment(today).subtract(1, "months").endOf("month");
  } else if (interval === "WEEKLY") {
    // Calculate the start date of the previous complete week (Tuesday)
    from_date = moment(today).subtract(1, "weeks").startOf("week");
    // Calculate the end date of the previous complete week (Tuesday)
    to_date = moment(today).subtract(1, "weeks").endOf("week");

    console.log("from_date", from_date);
    console.log("to_date", to_date);
  } else if (interval === "DAILY") {
    // Set from date to yesterday
    from_date = moment(today).subtract(1, "days");
    // Set to date to yesterday
    to_date = moment(today).subtract(1, "days");
  } else {
    // Default to today's date for invalid intervals
    from_date = today;
    to_date = today;
  }

  return {
    from_date: from_date.format("YYYY-MM-DD"),
    to_date: to_date.format("YYYY-MM-DD"),
  };
}

function getPreviousWeekDates(inputDate) {
  // // Parse inputDate using Moment.js
  // const currentDate = moment(inputDate);

  // // Calculate start date (subtract 7 days)
  // const startDate = moment(currentDate)
  //   .subtract(7, "days")
  //   .format("YYYY-MM-DD");

  // // Calculate end date (subtract 1 day from the current date)
  // const endDate = moment(currentDate).subtract(1, "days").format("YYYY-MM-DD");

  // Parse inputDate using Moment.js
  const currentDate = moment(inputDate);

  // Calculate end date (subtract 1 day from the current date)
  const endDate = moment(currentDate).subtract(1, "days").format("YYYY-MM-DD");

  // Calculate start date (subtract 7 days)
  const startDate = moment(currentDate)
    .subtract(7, "days")
    .format("YYYY-MM-DD");

  return { startDate, endDate };
}

function getFormattedDateIntoDayMonth(date) {
  return moment(date).format("DD MMM");
}

function getTheDayDiffrence(requestDate) {
  console.log("requestDate", requestDate);
  var yourDate = moment(requestDate);
  console.log("yourDate>>>>", yourDate);

  // Current date
  var currentDate = moment();

  // Calculate the difference in days
  var dayDifference = yourDate.diff(currentDate, "days");
  console.log("dayDifference", dayDifference);
  return dayDifference < 0 ? 0 : dayDifference;
}

export function getCurrentMonthDateUsingDay(dayOfMonth) {
  // Get the current date
  const currentDate = moment();

  // Set the day of the current date to the passed day of the month
  currentDate.date(dayOfMonth);

  return currentDate;
}

export function getCurrentMonthUsingDateString(dayOfMonth) {
  // Get the current date
  const currentDate = moment();

  // Set the day of the current date to the passed day of the month
  currentDate.date(dayOfMonth);

  return currentDate;
}

export function getCurrentMonthDate(dayOfMonth) {
  // Get the current date
  const currentDate = moment();
  // Set the day of the current date to the passed day of the month
  currentDate.date(dayOfMonth);

  return currentDate;
}

export function extractDayOfMonth(dateString) {
  // Parse the date string using Moment.js
  const parsedDate = moment(dateString, "YYYY-MM-DD");

  // Extract the day of the month
  const dayOfMonth = parsedDate.date();

  return dayOfMonth;
}

function covertDigitIntoMonthsAndDays(value) {
  const months = Math.floor(value / 30);
  const days = Math.round(value % 30);

  let result = "";
  if (months > 0) {
    result += `${months} month${months !== 1 ? "s" : ""}`;
  }
  if (days > 0) {
    if (result !== "") result += " and ";
    result += `${days} day${days !== 1 ? "s" : ""}`;
  }

  return result.trim();
}

function formatNumber(number) {
  console.log("number", number);
  /*
   * Format a number with commas as thousands separators and fixed decimal points.
   */

  if (typeof number === "string") {
    number = parseInt(number);
  }
  return number?.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
  // return 0;
}

function getTimeLabel(dateStr) {
  // Parse the date string using Moment.js
  const date = moment(dateStr);

  // Get the current date
  const currentDate = moment();

  // Calculate the difference between the dates
  const daysDifference = currentDate.diff(date, "days");
  const monthsDifference = currentDate.diff(date, "months");

  if (daysDifference === 0) {
    return "Today";
  } else if (daysDifference === 1) {
    return "1d ago";
  } else if (daysDifference < 30) {
    return `${daysDifference}d ago`;
  } else if (daysDifference === 30) {
    return `1m ago`;
  } else if (daysDifference === 31) {
    return `1m ago`;
  } else if (monthsDifference === 1) {
    return "1m ago";
  } else {
    return `${monthsDifference} months ago`;
  }

  // // Parse the date string using Moment.js and set time to the beginning of the day
  // const date = moment(dateStr).startOf("day");

  // // Get the current date
  // const currentDate = moment().startOf("day");

  // // Calculate the difference between the dates
  // const daysDifference = currentDate.diff(date, "days");
  // const monthsDifference = currentDate.diff(date, "months");

  // if (daysDifference === 0) {
  //   const hoursDifference = moment().diff(date, "hours");
  //   const minutesDifference = moment().diff(date, "minutes");
  //   const secondsDifference = moment().diff(date, "seconds");

  //   if (hoursDifference < 1) {
  //     return `${minutesDifference} minutes ago`;
  //   } else if (hoursDifference < 24) {
  //     return `${hoursDifference} hours ago`;
  //   } else if (minutesDifference < 60) {
  //     return `${minutesDifference} minutes ago`;
  //   } else if (secondsDifference < 60) {
  //     return `${secondsDifference} seconds ago`;
  //   } else {
  //     return "Today";
  //   }
  // } else if (daysDifference === 1) {
  //   return "1 day ago";
  // } else if (daysDifference < 30) {
  //   return `${daysDifference} days ago`;
  // } else if (monthsDifference === 1) {
  //   return "1 month ago";
  // } else {
  //   return `${monthsDifference} months ago`;
  // }
}

function compareSpending(currentWeek, lastWeek) {
  var difference = currentWeek - lastWeek;
  var value;

  // if (difference > 0) {
  //   value = Math.abs(difference) + " more as compared to last week.";
  // } else if (difference < 0) {
  //   value = Math.abs(difference) + " less as compared to last week.";
  // } else {
  //   value = "Spending stayed the same compared to last week.";
  // }

  value = difference;
  return value || 0;
}

function formatNumberInReadableForm(num) {
  let parseInNumbee = JSON.parse(num);
  console.log("num>>>>>", parseInNumbee);
  if (parseInNumbee >= 1000000) {
    return (parseInNumbee / 1000000).toFixed(1) + "M";
  } else if (parseInNumbee >= 1000) {
    return (parseInNumbee / 1000).toFixed(1) + "K";
  } else {
    return parseInNumbee?.toFixed(1).toString();
  }
}

export {
  notify,
  getDateInYYYY_MM_DD,
  formatDateInSimpleWord,
  getInitialName,
  createImageFromInitials,
  getFromDateToDate,
  getPreviousFromToDate,
  getTheDayDiffrence,
  getFormattedDateIntoDayMonth,
  covertDigitIntoMonthsAndDays,
  formatNumber,
  notificationNotify,
  getTimeLabel,
  getPreviousWeekDates,
  compareSpending,
  formatNumberInReadableForm,
};
