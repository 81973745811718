import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

const BorderLinearProgress = styled(LinearProgress)(({ theme, bgcolor }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: bgcolor,
  },
}));

function LinearProgressWithLabel({
  isPercentageShow = true,
  tittle,
  value,
  ...others
}) {
  return (
    <Box my={1}>
      <Box display="flex" justifyContent="space-between" mb={0.5}>
        <Typography variant="body2" fontWeight="500" color="#ffffff">
          {tittle}
        </Typography>
        {isPercentageShow === true && (
          <Typography
            variant="body1"
            fontWeight="500"
            // color={others.textColor ? others.textColor : others.bgcolor ? others.bgcolor : '#fff'}
            color={"#fff"}
          >{`${Math.round(value)}%`}</Typography>
        )}
      </Box>

      <Box sx={{ width: "100%" }}>
        <BorderLinearProgress value={value} {...others} />
      </Box>
    </Box>
  );
}

export default LinearProgressWithLabel;
