import API from "../../../utilities/API";

export const getGenderList = async () => {
  const url = "/api/v1/general/gender";
  const response = await API.get(url);

  return response.data;
};

export const getCountiresList = async () => {
  const url = "/api/v1/general/countries";
  const response = await API.get(url);

  return response.data;
};

export const getCardNetworkList = async () => {
  const url = "/api/v1/general/cardNetwork";
  const response = await API.get(url);

  return response.data;
};

export const cardTypeList = async () => {
  const url = "/api/v1/general/cardTypes";
  const response = await API.get(url);

  return response.data;
};

export const getStatusList = async () => {
  const url = "/api/v1/general/status";
  const response = await API.get(url);

  return response.data;
};

export const getCurrencyList = async () => {
  const url = "/api/v1/general/currency";
  const response = await API.get(url);

  return response.data;
};

export const getPaymentFrequencyList = async () => {
  const url = "/api/v1/general/paymentFrequency";
  const response = await API.get(url);

  return response.data;
};
export const getCategoryTypeList = async () => {
  const url = "/api/v1/general/categoryTypes";
  const response = await API.get(url);

  return response.data;
};
export const getTimeZoneList = async () => {
  const url = "/api/v1/general/timezones";
  const response = await API.get(url);

  return response.data;
};
