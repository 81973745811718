import { useEffect, useState } from "react";

// routing
import Routes from "routes";

// project imports
import Locales from "ui-component/Locales";
import NavigationScroll from "layout/NavigationScroll";
import RTLLayout from "ui-component/RTLLayout";
import Snackbar from "ui-component/extended/Snackbar";
import Loader from "ui-component/Loader";
import Notistack from "ui-component/third-party/Notistack";

import ThemeCustomization from "themes";
import { dispatch } from "store";
import { getMenu } from "store/slices/menu";
import "index.css";
import "react-circular-progressbar/dist/styles.css";
import "react-multi-carousel/lib/styles.css";
import "react-toastify/dist/ReactToastify.css";
// auth provider
import { JWTProvider as AuthProvider } from "contexts/JWTContext";
import Logo from "ui-component/Logo";
import { Box, Typography } from "@mui/material";
import Button from "views/ui-elements/basic/Button";
import { ToastContainer, toast } from "react-toastify";
import io from "socket.io-client";
import AddBoxIcon from "@mui/icons-material/AddBox";
import ShareBoxIcon from "./assets/images/share-icon.png";
import IconBox from "views/ui-elements/basic/IconBox";

// import { FirebaseProvider as AuthProvider } from 'contexts/FirebaseContext';
// import { AWSCognitoProvider as AuthProvider } from 'contexts/AWSCognitoContext';
// import { Auth0Provider as AuthProvider } from 'contexts/Auth0Context';

// ==============================|| APP ||============================== //

// import OneSignal from "react-onesignal";

const App = () => {
  const [loading, setLoading] = useState(false);

  const [isInstallable, setIsInstallable] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null);

  const [isAppInstalled, setIsAppInstalled] = useState(false);

  //   if ("serviceWorker" in navigator && "PushManager" in window) {
  //     // Check if the browser supports service workers and Push API

  //     navigator.serviceWorker.getRegistrations().then((registration) => {
  //       console.log("registration", registration);
  //       if (registration) {
  //         // Service worker is registered

  //         if (registration.active) {
  //           // Service worker is activated
  //           console.log("Your React app is installed as a PWA.");
  //         } else {
  //           console.log("Service worker is registered but not yet activated.");
  //         }
  //       } else {
  //         console.log("Service worker is not registered.");
  //       }
  //     });
  //   } else {
  //     console.log("PWAs are not supported in this browser.");
  //   }

  const BUTTON_PROPS = {
    variant: "contain",
    bg: "#0C7076",
    color: "#fff",

    radius: "13px",
    width: "100%",
    height: "45px",

    sx: {
      display: "block",
      my: "10px",
      py: "8px",
      px: "15px",
      fontWeight: "500",
      fontSize: "16px",
    },
  };

  // async function runOneSignal() {
  //   await OneSignal.init({
  //     appId: "xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx",
  //     allowLocalhostAsSecureOrigin: true,
  //   });
  //   OneSignal.Slidedown.promptPush();
  // }

  // useEffect(() => {
  //   runOneSignal();
  // });

  // const socket = io("https://api-finance.growthaccess.ae");

  // useEffect(() => {
  //   const connect = () => {
  //     console.log("Connected to Sockets Server");
  //   };

  //   socket.on("disconnect", () => {
  //     console.log("Disconnected");
  //   });

  //   socket.on("connect", connect);
  //   socket.on("connected", connect);
  //   return () => {
  //     socket.off("connect");
  //     socket.off("connected");
  //     socket.off("disconnect");
  //     socket.off("messageData");
  //   };
  // }, []);

  // fetchToken(setTokenFound);

  useEffect(() => {
    const handleBeforeInstallPrompt = (event) => {
      event.preventDefault();
      setDeferredPrompt(event);
      setIsInstallable(true);
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
  }, []);

  useEffect(() => {
    const checkInstallation = async () => {
      if ("getInstalledRelatedApps" in navigator) {
        const apps = await navigator.getInstalledRelatedApps();
        const isInstalled = apps.length > 0;
        console.log("Is app installed:", isInstalled);
      }
    };

    checkInstallation();
  }, []);

  useEffect(() => {
    dispatch(getMenu()).then(() => {
      setLoading(true);
    });
  }, []);

  const handleInstall = async () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      const choiceResult = await deferredPrompt.userChoice;
      if (choiceResult.outcome === "accepted") {
        setIsAppInstalled(true); // Update the state when the app is installed
        console.log("User accepted the install prompt");
      } else {
        console.log("User dismissed the install prompt");
      }
    }
  };
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

  const isStandalone = window.navigator.standalone;
  console.log("isIOS", isIOS, isStandalone);

  if (!loading) return <Loader />;

  return (
    <ThemeCustomization>
      <RTLLayout>
        <Locales>
          <NavigationScroll>
            {isIOS ? (
              <>
                {!isStandalone ? (
                  // <Box
                  //   className="d-flex align-items-center justify-content-center"
                  //   height="100vh"
                  //   display="flex"
                  //   alignItems="center"
                  // >
                  //   <Typography
                  //     variant="h2"
                  //     component="h2"
                  //     color="#0C7076"
                  //     textAlign="center"
                  //     sx={{
                  //       margin: "20px",
                  //       fontWeight: "bold",
                  //     }}
                  //   >
                  //     To install this app, tap the share icon and select "Add to
                  //     Home Screen."
                  //   </Typography>
                  // </Box>

                  <Box
                    className="d-flex align-items-center justify-content-center"
                    height="100vh"
                    display="flex"
                    alignItems="center"
                    margin="auto"
                    bgcolor="#000"
                    padding="0px 60px"
                  >
                    <div>
                      <Logo width="100" />
                      <Typography
                        variant="h6"
                        component="h6"
                        color="#fff"
                        textAlign="center"
                        fontSize="18px"
                        fontWeight="500"
                        sx={{
                          textAlign: "left",
                          margin: "20px 0px",
                          fontWeight: "bold",
                        }}
                      >
                        For Best User Experience ,We would recommend to install
                        MyFinance as a PWA.
                      </Typography>
                      <Box
                        margin="40px 0px"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Typography
                          variant="body1"
                          component="div"
                          color="#fff"
                        >
                          Find & Click on the share button
                        </Typography>

                        <IconBox
                          imgHeight="21px"
                          imgWidth="21px"
                          // bgcolor="#000"
                          // className="icon-img-color icon-box-img-p-3 "
                          url={ShareBoxIcon}
                        />
                      </Box>
                      <Box margin="40px 0px">
                        <Typography
                          variant="body1"
                          component="div"
                          color="#fff"
                        >
                          Then, Scroll and find
                        </Typography>
                      </Box>

                      <Box
                        bgcolor="#fff"
                        height="50px"
                        borderRadius="10px"
                        padding="0px 20px"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Typography
                          fontWeight="500"
                          variant="body1"
                          component="span"
                          color="#000"
                        >
                          Add to Home Screen
                        </Typography>
                        <AddBoxIcon color="#000" />
                      </Box>
                    </div>
                  </Box>
                ) : (
                  <AuthProvider>
                    <>
                      <Notistack>
                        <Routes />
                        <Snackbar />
                      </Notistack>
                    </>
                  </AuthProvider>
                )}
              </>
            ) : isInstallable && !isAppInstalled ? (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                flexDirection="column"
                padding="20px"
                height="100vh"
                maxWidth="475px"
                width="100%"
                margin="auto"
              >
                <Logo width="200" />

                <Button
                  {...BUTTON_PROPS}
                  sx={BUTTON_PROPS.sx}
                  onClick={() => handleInstall()}
                >
                  Install Application
                </Button>
              </Box>
            ) : (
              <>
                <AuthProvider>
                  <>
                    <Notistack>
                      <Routes />
                      <Snackbar />
                    </Notistack>
                  </>
                </AuthProvider>
              </>
            )}

            {/* {isInstallable && !isAppInstalled ? (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                flexDirection="column"
                padding="20px"
                height="100vh"
                maxWidth="475px"
                width="100%"
                margin="auto"
              >
                {isStandalone ? <p>True</p> : <p>false</p>}
                <Logo width="200" />

                <Button
                  {...BUTTON_PROPS}
                  sx={BUTTON_PROPS.sx}
                  onClick={() => handleInstall()}
                >
                  Install Application
                </Button>
              </Box>
            ) : (
              <>
                {isStandalone ? <p>True</p> : <p>false</p>}
                <AuthProvider>
                  <>
                    <Notistack>
                      <Routes />
                      <Snackbar />
                    </Notistack>
                  </>
                </AuthProvider>
              </>
            )} */}
            <ToastContainer />
          </NavigationScroll>
        </Locales>
      </RTLLayout>
    </ThemeCustomization>
  );
};

export default App;
