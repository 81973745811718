import React from "react";
import { Box, Typography, LinearProgress } from "@mui/material";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import LinearProgressWithLabel from "./LinearProgressBar";

const ItemDetailCardWithPrice = () => {
  let my_finance_user_in_strng = localStorage.getItem("my_finance_user");
  let my_finance_user_in_Json = JSON.parse(my_finance_user_in_strng);

  return (
    <Box
      bgcolor="rgb(31, 34, 39, 0.75)"
      borderRadius="16px"
      display="flex"
      justifyContent="space-between"
      mb={2}
      px={2}
      sx={{ cursor: "pointer" }}
      py={2}
    >
      <Box
        width="40px"
        height="40px"
        bgcolor="#000"
        borderRadius="50px"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <AccountBalanceIcon sx={{ color: "#fff", fontSize: "20px" }} />
      </Box>
      <Box ml={3} sx={{ flex: 1 }}>
        <Box display="flex" justifyContent="space-between">
          <Typography
            fontSize="14px"
            mb={0.5}
            variant="h4"
            component="h3"
            color="#fff"
            fontWeight="500"
          >
            Education
          </Typography>
        </Box>

        <Box display="flex" justifyContent="space-between" mt={0.5}>
          <Typography
            mb={0.5}
            fontSize="12px"
            variant="h2"
            component="h3"
            color="#fff"
            fontWeight="300"
          >
            {my_finance_user_in_Json &&
              my_finance_user_in_Json.wallet &&
              my_finance_user_in_Json.wallet.length > 0 &&
              my_finance_user_in_Json?.wallet[0]?.currency?.symbol}
            250 spent of{" "}
            {my_finance_user_in_Json &&
              my_finance_user_in_Json.wallet &&
              my_finance_user_in_Json.wallet.length > 0 &&
              my_finance_user_in_Json?.wallet[0]?.currency?.symbol}
            1000
          </Typography>

          <Typography
            mb={0.5}
            fontSize="12px"
            variant="h2"
            component="h3"
            color="#fff"
            fontWeight="300"
          >
            {my_finance_user_in_Json &&
              my_finance_user_in_Json.wallet &&
              my_finance_user_in_Json.wallet.length > 0 &&
              my_finance_user_in_Json?.wallet[0]?.currency?.symbol}
            750 left
          </Typography>
        </Box>
        <LinearProgress
          variant="determinate"
          sx={{ marginTop: "5px" }}
          value={73}
          color="secondary"
          aria-label='"traffic progress"'
        />
      </Box>
    </Box>
  );
};

export default ItemDetailCardWithPrice;
