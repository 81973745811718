import React from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const ShortcutCard = ({ id, onClick, iconUrl, tittle, route }) => {
  const navigate = useNavigate();
  const CARD_PROPS = {
    bgcolor: "rgb(31, 34, 39, 0.75)",
    borderRadius: "16px",
    minHeight: "200px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  };
  return (
    <Box
      {...CARD_PROPS}
      onClick={() =>
        onClick
          ? onClick(id)
          : route
          ? navigate(route)
          : console.log("no route")
      }
    >
      <img src={iconUrl} alt="my-account" />
      <Typography
        variant="h4"
        component="h4"
        color="#fff"
        fontWeight="500"
        sx={{ marginTop: 3 }}
      >
        {tittle || ""}
      </Typography>
    </Box>
  );
};

export default ShortcutCard;
