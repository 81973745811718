import { lazy } from "react";

// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import ViewTransaction from "ui-component/transactions/viewTransaction";
import MyDebtsCommingSoon from "views/pages/debt/comingsoon";

// --- Admin Panel Routes

//Users
const Users = Loadable(
  lazy(() => import("views/pages/admin/users/AdminUsers"))
);

//  Admin Panel Routes ---

// wallets routing
const Wallets = Loadable(lazy(() => import("views/pages/wallets/Wallets")));
const CreateWallet = Loadable(
  lazy(() => import("views/pages/wallets/CreateWallet"))
);

// logging routing
const CreateLogs = Loadable(lazy(() => import("views/pages/logs/Createlogs")));
const CreateEditPayment = Loadable(
  lazy(() => import("views/pages/payments/createEditPayment"))
);

const HomePage = Loadable(lazy(() => import("views/pages/home")));
const CashAccount = Loadable(lazy(() => import("views/pages/cash-accounts")));
const Reports = Loadable(lazy(() => import("views/pages/reports")));
const ReportsDetails = Loadable(
  lazy(() => import("views/pages/reports/reportsDetails"))
);
const ReportsHistory = Loadable(
  lazy(() => import("views/pages/reports/history"))
);
const TransactionHistoryListing = Loadable(
  lazy(() => import("views/pages/transaction/history"))
);
const CustomDashboard = Loadable(lazy(() => import("views/pages/dashboard")));

const Goals = Loadable(lazy(() => import("views/pages/goal")));
const GoalsDetails = Loadable(
  lazy(() => import("views/pages/goal/goal-details"))
);
const Account = Loadable(lazy(() => import("views/pages/account")));
const AccountDetails = Loadable(
  lazy(() => import("views/pages/account/accountDetails"))
);

// dashboard routing
const DashboardDefault = Loadable(
  lazy(() => import("views/dashboard/Default"))
);
const DashboardAnalytics = Loadable(
  lazy(() => import("views/dashboard/Analytics"))
);

// widget routing
const WidgetStatistics = Loadable(
  lazy(() => import("views/widget/Statistics"))
);
const WidgetData = Loadable(lazy(() => import("views/widget/Data")));
const WidgetChart = Loadable(lazy(() => import("views/widget/Chart")));

// application - user social & account profile routing
const AppUserSocialProfile = Loadable(
  lazy(() => import("views/application/users/social-profile"))
);
const AppUserAccountProfile1 = Loadable(
  lazy(() => import("views/application/users/account-profile/Profile1"))
);
const AppUserAccountProfile2 = Loadable(
  lazy(() => import("views/application/users/account-profile/Profile2"))
);
const AppUserAccountProfile3 = Loadable(
  lazy(() => import("views/application/users/account-profile/Profile3"))
);

// application - user cards & list variant routing
const AppProfileCardStyle1 = Loadable(
  lazy(() => import("views/application/users/card/CardStyle1"))
);
const AppProfileCardStyle2 = Loadable(
  lazy(() => import("views/application/users/card/CardStyle2"))
);
const AppProfileCardStyle3 = Loadable(
  lazy(() => import("views/application/users/card/CardStyle3"))
);
const AppProfileListStyle1 = Loadable(
  lazy(() => import("views/application/users/list/Style1"))
);
const AppProfileListStyle2 = Loadable(
  lazy(() => import("views/application/users/list/Style2"))
);

// application - customer routing
const AppCustomerList = Loadable(
  lazy(() => import("views/application/customer/CustomerList"))
);
const AppCustomerOrderList = Loadable(
  lazy(() => import("views/application/customer/OrderList"))
);
const AppCustomerCreateInvoice = Loadable(
  lazy(() => import("views/application/customer/CreateInvoice"))
);
const AppCustomerOrderDetails = Loadable(
  lazy(() => import("views/application/customer/OrderDetails"))
);
const AppCustomerProduct = Loadable(
  lazy(() => import("views/application/customer/Product"))
);
const AppCustomerProductReview = Loadable(
  lazy(() => import("views/application/customer/ProductReview"))
);

// application routing
const AppChat = Loadable(lazy(() => import("views/application/chat")));
const AppKanban = Loadable(lazy(() => import("views/application/kanban")));
const AppKanbanBacklogs = Loadable(
  lazy(() => import("views/application/kanban/Backlogs"))
);
const AppKanbanBoard = Loadable(
  lazy(() => import("views/application/kanban/Board"))
);
const AppMail = Loadable(lazy(() => import("views/application/mail")));
const AppCalendar = Loadable(lazy(() => import("views/application/calendar")));
const AppContactCard = Loadable(
  lazy(() => import("views/application/contact/Card"))
);
const AppContactList = Loadable(
  lazy(() => import("views/application/contact/List"))
);

// application e-commerce pages
const AppECommProducts = Loadable(
  lazy(() => import("views/application/e-commerce/Products"))
);
const AppECommProductDetails = Loadable(
  lazy(() => import("views/application/e-commerce/ProductDetails"))
);
const AppECommProductList = Loadable(
  lazy(() => import("views/application/e-commerce/ProductList"))
);
const AppECommCheckout = Loadable(
  lazy(() => import("views/application/e-commerce/Checkout"))
);

// forms component routing
const FrmComponentsTextfield = Loadable(
  lazy(() => import("views/forms/components/TextField"))
);
const FrmComponentsButton = Loadable(
  lazy(() => import("views/forms/components/Button"))
);
const FrmComponentsCheckbox = Loadable(
  lazy(() => import("views/forms/components/Checkbox"))
);
const FrmComponentsRadio = Loadable(
  lazy(() => import("views/forms/components/Radio"))
);
const FrmComponentsSwitch = Loadable(
  lazy(() => import("views/forms/components/Switch"))
);
const FrmComponentsAutoComplete = Loadable(
  lazy(() => import("views/forms/components/AutoComplete"))
);
const FrmComponentsSlider = Loadable(
  lazy(() => import("views/forms/components/Slider"))
);
const FrmComponentsDateTime = Loadable(
  lazy(() => import("views/forms/components/DateTime"))
);

// forms plugins layout
const FrmLayoutLayout = Loadable(
  lazy(() => import("views/forms/layouts/Layouts"))
);
const FrmLayoutMultiColumnForms = Loadable(
  lazy(() => import("views/forms/layouts/MultiColumnForms"))
);
const FrmLayoutActionBar = Loadable(
  lazy(() => import("views/forms/layouts/ActionBar"))
);
const FrmLayoutStickyActionBar = Loadable(
  lazy(() => import("views/forms/layouts/StickyActionBar"))
);

// forms plugins routing
const FrmAutocomplete = Loadable(
  lazy(() => import("views/forms/plugins/AutoComplete"))
);
const FrmMask = Loadable(lazy(() => import("views/forms/plugins/Mask")));
const FrmClipboard = Loadable(
  lazy(() => import("views/forms/plugins/Clipboard"))
);
const FrmRecaptcha = Loadable(
  lazy(() => import("views/forms/plugins/Recaptcha"))
);
const FrmWysiwugEditor = Loadable(
  lazy(() => import("views/forms/plugins/WysiwugEditor"))
);
const FrmModal = Loadable(lazy(() => import("views/forms/plugins/Modal")));
const FrmTooltip = Loadable(lazy(() => import("views/forms/plugins/Tooltip")));

// table routing
const TableBasic = Loadable(
  lazy(() => import("views/forms/tables/TableBasic"))
);
const TableDense = Loadable(
  lazy(() => import("views/forms/tables/TableDense"))
);
const TableEnhanced = Loadable(
  lazy(() => import("views/forms/tables/TableEnhanced"))
);
const TableData = Loadable(lazy(() => import("views/forms/tables/TableData")));
const TableCustomized = Loadable(
  lazy(() => import("views/forms/tables/TablesCustomized"))
);
const TableStickyHead = Loadable(
  lazy(() => import("views/forms/tables/TableStickyHead"))
);
const TableCollapsible = Loadable(
  lazy(() => import("views/forms/tables/TableCollapsible"))
);

// forms validation
const FrmFormsValidation = Loadable(
  lazy(() => import("views/forms/forms-validation"))
);
const FrmFormsWizard = Loadable(lazy(() => import("views/forms/forms-wizard")));

// chart routing
const ChartApexchart = Loadable(
  lazy(() => import("views/forms/chart/Apexchart"))
);
const OrgChartPage = Loadable(lazy(() => import("views/forms/chart/OrgChart")));

// basic ui-elements routing
const BasicUIAccordion = Loadable(
  lazy(() => import("views/ui-elements/basic/UIAccordion"))
);
const BasicUIAvatar = Loadable(
  lazy(() => import("views/ui-elements/basic/UIAvatar"))
);
const BasicUIBadges = Loadable(
  lazy(() => import("views/ui-elements/basic/UIBadges"))
);
const BasicUIBreadcrumb = Loadable(
  lazy(() => import("views/ui-elements/basic/UIBreadcrumb"))
);
const BasicUICards = Loadable(
  lazy(() => import("views/ui-elements/basic/UICards"))
);
const BasicUIChip = Loadable(
  lazy(() => import("views/ui-elements/basic/UIChip"))
);
const BasicUIList = Loadable(
  lazy(() => import("views/ui-elements/basic/UIList"))
);
const BasicUITabs = Loadable(
  lazy(() => import("views/ui-elements/basic/UITabs"))
);

// advance ui-elements routing
const AdvanceUIAlert = Loadable(
  lazy(() => import("views/ui-elements/advance/UIAlert"))
);
const AdvanceUIDialog = Loadable(
  lazy(() => import("views/ui-elements/advance/UIDialog"))
);
const AdvanceUIPagination = Loadable(
  lazy(() => import("views/ui-elements/advance/UIPagination"))
);
const AdvanceUIProgress = Loadable(
  lazy(() => import("views/ui-elements/advance/UIProgress"))
);
const AdvanceUIRating = Loadable(
  lazy(() => import("views/ui-elements/advance/UIRating"))
);
const AdvanceUISnackbar = Loadable(
  lazy(() => import("views/ui-elements/advance/UISnackbar"))
);
const AdvanceUISkeleton = Loadable(
  lazy(() => import("views/ui-elements/advance/UISkeleton"))
);
const AdvanceUISpeeddial = Loadable(
  lazy(() => import("views/ui-elements/advance/UISpeeddial"))
);
const AdvanceUITimeline = Loadable(
  lazy(() => import("views/ui-elements/advance/UITimeline"))
);
const AdvanceUIToggleButton = Loadable(
  lazy(() => import("views/ui-elements/advance/UIToggleButton"))
);
const AdvanceUITreeview = Loadable(
  lazy(() => import("views/ui-elements/advance/UITreeview"))
);

// pricing page routing
const PagesPrice1 = Loadable(lazy(() => import("views/pages/pricing/Price1")));
const PagesPrice2 = Loadable(lazy(() => import("views/pages/pricing/Price2")));

// utilities routing
const UtilsTypography = Loadable(
  lazy(() => import("views/utilities/Typography"))
);
const UtilsColor = Loadable(lazy(() => import("views/utilities/Color")));
const UtilsShadow = Loadable(lazy(() => import("views/utilities/Shadow")));
const UtilsAnimation = Loadable(
  lazy(() => import("views/utilities/Animation"))
);
const UtilsGrid = Loadable(lazy(() => import("views/utilities/Grid")));

// sample page routing
const SamplePage = Loadable(lazy(() => import("views/sample-page")));

// New  pages routing
const Splash = Loadable(lazy(() => import("views/pages/splash")));
const OnBoard = Loadable(lazy(() => import("views/pages/onBoard")));

const Questionare = Loadable(lazy(() => import("views/pages/questionare")));
// const PickProfilePicture = Loadable(
//   lazy(() => import("views/pages/pickProfilePicture"))
// );
// const AuthQuestion = Loadable(lazy(() => import("views/pages/authQuestion")));
// const PrivacyPolicy = Loadable(lazy(() => import("views/pages/privacyPolicy")));
const TermsAndCondition = Loadable(
  lazy(() => import("views/pages/termsCondition"))
);

// const EmailVerification = Loadable(
//   lazy(() => import("views/pages/emailVerification"))
// );

const ProfileOptions = Loadable(
  lazy(() => import("views/pages/menue-bar/profile"))
);
const EditProfile = Loadable(
  lazy(() => import("views/pages/menue-bar/editProfile"))
);
const NotificationSetting = Loadable(
  lazy(() => import("views/pages/menue-bar/notificationSetting"))
);
const PrivacyPollicySetting = Loadable(
  lazy(() => import("views/pages/menue-bar/privacyPolicy"))
);
const NotificationList = Loadable(
  lazy(() => import("views/pages/menue-bar/notificationList"))
);

const CreateAccount = Loadable(
  lazy(() => import("views/pages/accountCreation"))
);
const CreateAccountSuccessfully = Loadable(
  lazy(() => import("views/pages/accountCreation/creationSuccessfully"))
);
const CreateAccountSubmition = Loadable(
  lazy(() => import("views/pages/accountCreation/accountCreationForm"))
);
const MyWallets = Loadable(lazy(() => import("views/pages/myWallets")));

const MySubscription = Loadable(
  lazy(() => import("views/pages/mySubscription"))
);
const ChosePlan = Loadable(lazy(() => import("views/pages/chosePlan")));
const PlanDetails = Loadable(lazy(() => import("views/pages/planDetails")));
const PaymentDetails = Loadable(
  lazy(() => import("views/pages/paymentDetails"))
);
const EditAccount = Loadable(
  lazy(() => import("views/pages/account/editAccount"))
);
const SavingAccountDetails = Loadable(
  lazy(() => import("views/pages/account/savingAccountDetails"))
);
const TransActionDetails = Loadable(
  lazy(() => import("views/pages/transactionAndPayment/transaction-details"))
);
const TransactionHistory = Loadable(
  lazy(() => import("views/pages/transactionAndPayment/transactionHistory"))
);
const PaymentCoveredSuccessfully = Loadable(
  lazy(() =>
    import("views/pages/transactionAndPayment/paymentCoveredSuccessfully")
  )
);

const CardList = Loadable(lazy(() => import("views/pages/card")));
const AddCard = Loadable(lazy(() => import("views/pages/card/addCard")));
const AddCardSuccessfully = Loadable(
  lazy(() => import("views/pages/card/addCardSuccessfully"))
);
const SavingGoalSuccessfully = Loadable(
  lazy(() => import("views/pages/goal/savingGoalSuccessfully"))
);
const GoalsList = Loadable(lazy(() => import("views/pages/goal/goalList")));
const FilteredGoalsList = Loadable(
  lazy(() => import("views/pages/goal/filteredGoals"))
);
const CreateGoal = Loadable(lazy(() => import("views/pages/goal/createGoal")));

const Debt = Loadable(lazy(() => import("views/pages/debt")));
const DebtCardList = Loadable(lazy(() => import("views/pages/debt/cardsList")));
const DebtCardDetails = Loadable(
  lazy(() => import("views/pages/debt/cardDetails"))
);
const AddDebtCard = Loadable(
  lazy(() => import("views/pages/debt/addCreditCard"))
);
const AddDebtCardSuccessfully = Loadable(
  lazy(() => import("views/pages/debt/addCreditCard/successfullyAdded"))
);

const StatementList = Loadable(lazy(() => import("views/pages/statements")));
const StatementDetails = Loadable(
  lazy(() => import("views/pages/statements/statementsDetails"))
);
const ALlLoanList = Loadable(lazy(() => import("views/pages/loan")));
const LoanDetails = Loadable(
  lazy(() => import("views/pages/loan/loanDetails"))
);
const CreateLoan = Loadable(
  lazy(() => import("views/pages/loan/createLoanForm"))
);
const MyDebtReports = Loadable(lazy(() => import("views/pages/myDebtReports")));
const Reconcollation = Loadable(
  lazy(() => import("views/pages/reconcollation"))
);

const NewLogs = Loadable(lazy(() => import("views/pages/logsNew")));
const LogsCreatedSuccessfully = Loadable(
  lazy(() => import("views/pages/logsNew/logsCreatedSuccessfully"))
);
const SelectCategory = Loadable(lazy(() => import("views/pages/categories")));
const EditCategory = Loadable(
  lazy(() => import("views/pages/categories/editCategory"))
);

const CustomizedWidget = Loadable(
  lazy(() => import("views/pages/dashboardSettings"))
);

const CreateCategory = Loadable(
  lazy(() => import("views/pages/categories/createCategory"))
);
const CreateSubCategory = Loadable(
  lazy(() => import("views/pages/categories/createSubCategory"))
);
const ChooseIcon = Loadable(
  lazy(() => import("views/pages/categories/chooseCategoryIcon"))
);
const CategoryCreatedSuccessfully = Loadable(
  lazy(() => import("views/pages/categories/categoryCreatedSuccessfully"))
);
const MyPayments = Loadable(lazy(() => import("views/pages/payments")));
const MyPaymentCreate = Loadable(
  lazy(() => import("views/pages/payments/paymentCreated"))
);
const MyReserve = Loadable(lazy(() => import("views/pages/myReserve")));
const MyReserveSetting = Loadable(
  lazy(() => import("views/pages/myReserve/reserveSetting"))
);
const MyReserveManual = Loadable(
  lazy(() => import("views/pages/myReserve/manualReserve"))
);

const BudgetDashboard = Loadable(lazy(() => import("views/pages/budget")));
const ManageBudget = Loadable(
  lazy(() => import("views/pages/budget/manageBudget"))
);
const CreateEditBudget = Loadable(
  lazy(() => import("views/pages/budget/createBudget"))
);

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: "/admin/users",
      element: <Users />,
    },
    {
      path: "/home",
      element: <HomePage />,
    },
    {
      path: "/cash-accounts",
      element: <CashAccount />,
    },

    {
      path: "/reports",
      element: <Reports />,
    },
    {
      path: "/reports/reports-details",
      element: <ReportsDetails />,
    },
    {
      path: "/reports/history",
      element: <ReportsHistory />,
    },
    {
      path: "/custom-dashboard",
      element: <CustomDashboard />,
    },

    {
      path: "/transaction-view",
      element: <ViewTransaction />,
    },

    {
      path: "/saving-goal",
      element: <Goals />,
    },

    {
      path: "/saving-goal-details",
      element: <GoalsDetails />,
    },

    {
      path: "/accounts",
      element: <Account />,
    },
    {
      path: "/account-details",
      element: <AccountDetails />,
    },
    {
      path: "/wallets/listwallets",
      element: <Wallets />,
    },
    {
      path: "/wallets/createwallet",
      element: <CreateWallet />,
    },

    {
      path: "/logs/createlogs",
      element: <CreateLogs />,
    },

    {
      path: "/payment/create-payment",
      element: <CreateEditPayment />,
    },

    {
      path: "/widget/statistics",
      element: <WidgetStatistics />,
    },
    {
      path: "/widget/data",
      element: <WidgetData />,
    },
    {
      path: "/widget/chart",
      element: <WidgetChart />,
    },

    {
      path: "/user/social-profile/:tab",
      element: <AppUserSocialProfile />,
    },
    {
      path: "/user/account-profile/profile1",
      element: <AppUserAccountProfile1 />,
    },
    {
      path: "/user/account-profile/profile2",
      element: <AppUserAccountProfile2 />,
    },
    {
      path: "/user/account-profile/profile3",
      element: <AppUserAccountProfile3 />,
    },

    {
      path: "/user/card/card1",
      element: <AppProfileCardStyle1 />,
    },
    {
      path: "/user/card/card2",
      element: <AppProfileCardStyle2 />,
    },
    {
      path: "/user/card/card3",
      element: <AppProfileCardStyle3 />,
    },
    {
      path: "/user/list/list1",
      element: <AppProfileListStyle1 />,
    },
    {
      path: "/user/list/list2",
      element: <AppProfileListStyle2 />,
    },

    {
      path: "/customer/customer-list",
      element: <AppCustomerList />,
    },
    {
      path: "/customer/order-list",
      element: <AppCustomerOrderList />,
    },
    {
      path: "/customer/create-invoice",
      element: <AppCustomerCreateInvoice />,
    },
    {
      path: "/customer/order-details",
      element: <AppCustomerOrderDetails />,
    },
    {
      path: "/customer/product",
      element: <AppCustomerProduct />,
    },
    {
      path: "/customer/product-review",
      element: <AppCustomerProductReview />,
    },

    {
      path: "/app/chat",
      element: <AppChat />,
    },
    {
      path: "/app/mail",
      element: <AppMail />,
    },
    {
      path: "/app/kanban",
      element: <AppKanban />,
      children: [
        {
          path: "backlogs",
          element: <AppKanbanBacklogs />,
        },
        {
          path: "board",
          element: <AppKanbanBoard />,
        },
      ],
    },
    {
      path: "/app/calendar",
      element: <AppCalendar />,
    },
    {
      path: "/app/contact/c-card",
      element: <AppContactCard />,
    },
    {
      path: "/app/contact/c-list",
      element: <AppContactList />,
    },

    {
      path: "/e-commerce/products",
      element: <AppECommProducts />,
    },
    {
      path: "/e-commerce/product-details/:id",
      element: <AppECommProductDetails />,
    },
    {
      path: "/e-commerce/product-list",
      element: <AppECommProductList />,
    },
    {
      path: "/e-commerce/checkout",
      element: <AppECommCheckout />,
    },

    {
      path: "/components/text-field",
      element: <FrmComponentsTextfield />,
    },
    {
      path: "/components/button",
      element: <FrmComponentsButton />,
    },
    {
      path: "/components/checkbox",
      element: <FrmComponentsCheckbox />,
    },
    {
      path: "/components/radio",
      element: <FrmComponentsRadio />,
    },
    {
      path: "/components/autocomplete",
      element: <FrmComponentsAutoComplete />,
    },
    {
      path: "/components/slider",
      element: <FrmComponentsSlider />,
    },
    {
      path: "/components/switch",
      element: <FrmComponentsSwitch />,
    },
    {
      path: "/components/date-time",
      element: <FrmComponentsDateTime />,
    },

    {
      path: "/forms/layouts/layouts",
      element: <FrmLayoutLayout />,
    },
    {
      path: "/forms/layouts/multi-column-forms",
      element: <FrmLayoutMultiColumnForms />,
    },
    {
      path: "/forms/layouts/action-bar",
      element: <FrmLayoutActionBar />,
    },
    {
      path: "/forms/layouts/sticky-action-bar",
      element: <FrmLayoutStickyActionBar />,
    },

    {
      path: "/forms/frm-autocomplete",
      element: <FrmAutocomplete />,
    },
    {
      path: "/forms/frm-mask",
      element: <FrmMask />,
    },
    {
      path: "/forms/frm-clipboard",
      element: <FrmClipboard />,
    },
    {
      path: "/forms/frm-recaptcha",
      element: <FrmRecaptcha />,
    },
    {
      path: "/forms/frm-wysiwug",
      element: <FrmWysiwugEditor />,
    },
    {
      path: "/forms/frm-modal",
      element: <FrmModal />,
    },
    {
      path: "/forms/frm-tooltip",
      element: <FrmTooltip />,
    },

    {
      path: "/tables/tbl-basic",
      element: <TableBasic />,
    },
    {
      path: "/tables/tbl-dense",
      element: <TableDense />,
    },
    {
      path: "/tables/tbl-enhanced",
      element: <TableEnhanced />,
    },
    {
      path: "/tables/tbl-data",
      element: <TableData />,
    },
    {
      path: "/tables/tbl-customized",
      element: <TableCustomized />,
    },
    {
      path: "/tables/tbl-sticky-header",
      element: <TableStickyHead />,
    },
    {
      path: "/tables/tbl-collapse",
      element: <TableCollapsible />,
    },

    {
      path: "forms/charts/apexchart",
      element: <ChartApexchart />,
    },
    {
      path: "/forms/charts/orgchart",
      element: <OrgChartPage />,
    },
    {
      path: "/forms/forms-validation",
      element: <FrmFormsValidation />,
    },
    {
      path: "/forms/forms-wizard",
      element: <FrmFormsWizard />,
    },

    {
      path: "/basic/accordion",
      element: <BasicUIAccordion />,
    },
    {
      path: "/basic/avatar",
      element: <BasicUIAvatar />,
    },
    {
      path: "/basic/badges",
      element: <BasicUIBadges />,
    },
    {
      path: "/basic/breadcrumb",
      element: <BasicUIBreadcrumb />,
    },
    {
      path: "/basic/cards",
      element: <BasicUICards />,
    },
    {
      path: "/basic/chip",
      element: <BasicUIChip />,
    },
    {
      path: "/basic/list",
      element: <BasicUIList />,
    },
    {
      path: "/basic/tabs",
      element: <BasicUITabs />,
    },

    {
      path: "/advance/alert",
      element: <AdvanceUIAlert />,
    },
    {
      path: "/advance/dialog",
      element: <AdvanceUIDialog />,
    },
    {
      path: "/advance/pagination",
      element: <AdvanceUIPagination />,
    },
    {
      path: "/advance/progress",
      element: <AdvanceUIProgress />,
    },
    {
      path: "/advance/rating",
      element: <AdvanceUIRating />,
    },
    {
      path: "/advance/snackbar",
      element: <AdvanceUISnackbar />,
    },
    {
      path: "/advance/skeleton",
      element: <AdvanceUISkeleton />,
    },
    {
      path: "/advance/speeddial",
      element: <AdvanceUISpeeddial />,
    },
    {
      path: "/advance/timeline",
      element: <AdvanceUITimeline />,
    },
    {
      path: "/advance/toggle-button",
      element: <AdvanceUIToggleButton />,
    },
    {
      path: "/advance/treeview",
      element: <AdvanceUITreeview />,
    },

    {
      path: "/pages/price/price1",
      element: <PagesPrice1 />,
    },
    {
      path: "/pages/price/price2",
      element: <PagesPrice2 />,
    },

    {
      path: "/utils/util-typography",
      element: <UtilsTypography />,
    },
    {
      path: "/utils/util-color",
      element: <UtilsColor />,
    },
    {
      path: "/utils/util-shadow",
      element: <UtilsShadow />,
    },
    {
      path: "/utils/util-animation",
      element: <UtilsAnimation />,
    },
    {
      path: "/utils/util-grid",
      element: <UtilsGrid />,
    },
    {
      path: "/sample-page",
      element: <SamplePage />,
    },
    {
      path: "/dashboard/default",
      element: <DashboardDefault />,
    },
    {
      path: "/dashboard/analytics",
      element: <DashboardAnalytics />,
    },

    // new routes
    {
      path: "/splash-screen",
      element: <Splash />,
    },
    // {
    //   path: "/on-board",
    //   element: <OnBoard />,
    // },
    {
      path: "/questionare",
      element: <Questionare />,
    },
    // {
    //   path: "/pick-profile-picture",
    //   element: <PickProfilePicture />,
    // },

    // {
    //   path: "/email-verification",
    //   element: <EmailVerification />,
    // },
    {
      path: "/settings/profile",
      element: <ProfileOptions />,
    },
    {
      path: "/settings/edit-profile",
      element: <EditProfile />,
    },
    {
      path: "/settings/notifications",
      element: <NotificationSetting />,
    },
    {
      path: "/settings/privacy-policy",
      element: <PrivacyPollicySetting />,
    },
    {
      path: "/settings/terms-condition",
      element: <TermsAndCondition />,
    },
    {
      path: "/notification-list",
      element: <NotificationList />,
    },
    {
      path: "/create-account",
      element: <CreateAccount />,
    },
    {
      path: "/create-account-submition",
      element: <CreateAccountSubmition />,
    },
    {
      path: "/create-account-successfully",
      element: <CreateAccountSuccessfully />,
    },
    {
      path: "/my-wallets",
      element: <MyWallets />,
    },
    {
      path: "/my-subscription",
      element: <MySubscription />,
    },
    {
      path: "/chose-plan",
      element: <ChosePlan />,
    },
    {
      path: "/plan-details",
      element: <PlanDetails />,
    },
    {
      path: "/payment-details",
      element: <PaymentDetails />,
    },
    {
      path: "/edit-account",
      element: <EditAccount />,
    },
    {
      path: "/saving-account-details",
      element: <SavingAccountDetails />,
    },
    {
      path: "/transaction-details",
      element: <TransActionDetails />,
    },
    {
      path: "/transaction-history",
      element: <TransactionHistory />,
    },
    {
      path: "/transaction-history-listing",
      element: <TransactionHistoryListing />,
    },
    {
      path: "/payment-covered-successfully",
      element: <PaymentCoveredSuccessfully />,
    },
    {
      path: "/debit-cards-list",
      element: <CardList />,
    },
    {
      path: "/add-debit-card-form",
      element: <AddCard />,
    },
    {
      path: "/add-debit-card-successfully",
      element: <AddCardSuccessfully />,
    },
    {
      path: "/saving-goal-successfully",
      element: <SavingGoalSuccessfully />,
    },
    {
      path: "/goals-list",
      element: <GoalsList />,
    },
    {
      path: "/filtered-goals-list",
      element: <FilteredGoalsList />,
    },
    {
      path: "/create-goal",
      element: <CreateGoal />,
    },
    {
      path: "/debt",
      element: <Debt />,
    },
    {
      path: "/debt-card-list",
      element: <DebtCardList />,
    },
    {
      path: "/debt-card-details",
      element: <DebtCardDetails />,
    },
    {
      path: "/add-debt-card",
      element: <AddDebtCard />,
    },
    {
      path: "/add-debt-card-successfully",
      element: <AddDebtCardSuccessfully />,
    },
    {
      path: "/statement-list",
      element: <StatementList />,
    },
    {
      path: "/statement-details",
      element: <StatementDetails />,
    },
    {
      path: "/loan-list",
      element: <ALlLoanList />,
    },
    {
      path: "/loan-details",
      element: <LoanDetails />,
    },
    {
      path: "/create-laon",
      element: <CreateLoan />,
    },
    {
      path: "/my-debt-report",
      element: <MyDebtReports />,
    },

    {
      path: "/reconcillation",
      element: <Reconcollation />,
    },

    {
      path: "/new-logs",
      element: <NewLogs />,
    },
    {
      path: "/create-payment",
      element: <NewLogs />,
    },

    {
      path: "/logs-created-successfully",
      element: <LogsCreatedSuccessfully />,
    },

    {
      path: "/select-category",
      element: <SelectCategory />,
    },
    {
      path: "/edit-category",
      element: <EditCategory />,
    },
    {
      path: "/customized-widget",
      element: <CustomizedWidget />,
    },

    {
      path: "/create-category",
      element: <CreateCategory />,
    },

    {
      path: "/create-sub-category",
      element: <CreateSubCategory />,
    },

    {
      path: "/choose-icons",
      element: <ChooseIcon />,
    },
    {
      path: "/category-created-succesfully",
      element: <CategoryCreatedSuccessfully />,
    },
    {
      path: "/my-payments",
      element: <MyPayments />,
    },
    {
      path: "/my-payments-created",
      element: <MyPaymentCreate />,
    },
    {
      path: "/my-reserve",
      element: <MyReserve />,
    },
    {
      path: "/my-reserve-setting",
      element: <MyReserveSetting />,
    },
    {
      path: "/my-reserve-manual",
      element: <MyReserveManual />,
    },
    {
      path: "/budget-dashbaord",
      element: <BudgetDashboard />,
    },
    {
      path: "/debt-commingsoon",
      element: <MyDebtsCommingSoon />,
    },
    {
      path: "/manage-budget",
      element: <ManageBudget />,
    },
    {
      path: "/create-edit-budget",
      element: <CreateEditBudget />,
    },
  ],
};

export default MainRoutes;
