import PropTypes from "prop-types";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

// project imports
import useAuth from "hooks/useAuth";
import { DASHBOARD_PATH } from "config";
import { useDispatch, useSelector } from "react-redux";
import { getUserWalletListSlice } from "store/wallets/walletsSlice/wallets.slice";

// ==============================|| GUEST GUARD ||============================== //

/**
 * Guest guard for routes having no auth required
 * @param {PropTypes.node} children children element/node
 */

const GuestGuard = ({ children }) => {
  const { isLoggedIn, user, isTwoFactor } = useAuth();
  const { userWallets, isError, isSuccess, isLoading, message } = useSelector(
    (state) => state.wallets
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  console.log("user>>>>>>>>>>>>>", user);

  useEffect(() => {
    if (user?.id) {
      dispatch(getUserWalletListSlice({ userId: user?.id }));
    }
  }, [user]);

  console.log("user?.wallet", user?.wallet?.length);

  useEffect(() => {
    console.log("isLoggedIn", isLoggedIn);
    if (isLoggedIn && isTwoFactor === false) {
      if (user?.role_id === 2) {
        if (user?.profile_complete_step === 2) {
          navigate("/questionare", { replace: true });
        } else if (user?.profile_complete_step == 3) {
          navigate("/auth-questions", { replace: true });
        } else if (user?.profile_complete_step == 4) {
          navigate("/pick-profile-picture", { replace: true });
        } else if (user?.profile_complete_step == 5) {
          navigate("/privacy-policy", { replace: true });
        } else if (user?.wallet?.length === 0) {
          console.log(
            "user?.wallet?.length === 0 || userWallets.length === 0",
            user?.wallet?.length === 0
          );
          navigate("/wallets/createwallet", { replace: true });
        } else {
          navigate("/custom-dashboard", { replace: true });
        }
      } else if (user?.role_id === 1) {
        navigate("/admin/users", { replace: true });
      }

      // else {
      //   navigate("/two-factor-otp", { state: user });
      // }
    } else if (isTwoFactor === true) {
      navigate("/two-factor-otp", { state: user });
    }
  }, [isLoggedIn, navigate]);

  return children;
};

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default GuestGuard;
