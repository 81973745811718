import API from "../../../utilities/API";

export const getDebitCardList = async () => {
  const url = `/api/v1/accountCard`;
  const response = await API.get(url);

  return response.data;
};

export const getDebitCardDetails = async (debitCardId) => {
  const url = `/api/v1/accountCard/${debitCardId}`;
  const response = await API.get(url);

  return response.data;
};

export const createDebitCard = async (payload) => {
  const url = "/api/v1/accountCard";
  const response = await API.post(url, payload);

  return response.data;
};

export const updateDebitCard = async (debitCardId, updatePayload) => {
  const url = `/api/v1/accountCard/${debitCardId}`;
  const response = await API.put(url, updatePayload);

  return response.data;
};

export const deleteDebitCard = async (debitCardId) => {
  const url = `/api/v1/accountCard/${debitCardId}`;
  const response = await API.delete(url);

  return response.data;
};
