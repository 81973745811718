// third-party
import { FormattedMessage } from "react-intl";

// assets
import {
  IconMoneybag,
  IconTargetArrow,
  IconBuildingBank,
  IconCashBanknote,
  IconDashboard,
  IconDeviceAnalytics,
  IconWallet,
  IconLayoutDashboard,
  IconCoin,
  IconHomeDollar,
  IconReportAnalytics,
  IconCrown,
  IconUsers,
  IconWorld,
} from "@tabler/icons";
import CategoryIcon from "@mui/icons-material/Category";
import PolicyIcon from "@mui/icons-material/Policy";
import GradingIcon from "@mui/icons-material/Grading";

const icons = {
  IconDashboard,
  IconDeviceAnalytics,
  IconWallet,
  IconBuildingBank,
  IconCashBanknote,
  CategoryIcon,
  GradingIcon,
  PolicyIcon,
  IconTargetArrow,
  IconMoneybag,
  IconLayoutDashboard,
  IconCoin,
  IconHomeDollar,
  IconReportAnalytics,
  IconCrown,
  IconUsers,
  IconWorld,
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const adminDashboard = {
  id: "adminDashboard",
  title: " ",
  icon: icons.IconDashboard,
  type: "group",
  children: [
    {
      id: "dashboard",
      title: "MyDashboard",
      type: "item",
      url: "dashboard/analytics",
      icon: icons.IconLayoutDashboard,
      breadcrumbs: true,
    },
    {
      id: "users",
      title: "Users",
      type: "item",
      url: "admin/users",
      icon: icons.IconUsers,
      breadcrumbs: true,
    },
    {
      id: "country",
      title: "Country",
      type: "item",
      url: "admin/country",
      icon: icons.IconWorld,
      breadcrumbs: true,
    },
    // {
    //   id: "subscriptionSales",
    //   title: "Subscription Sales",
    //   type: "item",
    //   url: "admin/subscription-sales",
    //   icon: icons.IconCashBanknote,
    //   breadcrumbs: true,
    // },
    {
      id: "categories",
      title: "Categories",
      type: "item",
      url: "admin/categories",
      icon: icons.CategoryIcon,
      breadcrumbs: true,
    },

    {
      id: "terms",
      title: "Terms & Conditions",
      type: "item",
      url: "admin/terms",
      icon: icons.GradingIcon,
      breadcrumbs: true,
    },
    {
      id: "Policy",
      title: "Privacy Policy",
      type: "item",
      url: "admin/policy",
      icon: icons.PolicyIcon,
      breadcrumbs: true,
    },
  ],
};

export default adminDashboard;
