import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  changePasswordRequest,
  getBehaviorQuestions,
  getSecurityQuestions,
  refreshToken,
  resetPasswordService,
  saveSecurityQuestions,
  saveUserBehaviorQuestions,
  updateUserProfile,
  userLogin,
  userRegisterWithEmail,
  userVerificationViaEmail,
} from "../AuthServices/authServices";

const initialState = {
  newUser: {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    planId: null,
    roleId: null,
  },
  behaviorQuestions: [],
  securityQuestions: [],
  user: null,
  isError: false,
  isSuccess: false,
  isLoginSuccess: false,
  isLoading: false,
  message: "",
  isLoggedIn: false,
  isTwoFactor: false,
};

//  user Register
export const userSignUpWithEmail = createAsyncThunk(
  "auth/userSignUpWithEmail",
  async ({ user, moveToNext, onFailure }, thunkAPI) => {
    console.log("user", user);
    try {
      const response = await userRegisterWithEmail(user);
      if (moveToNext) {
        moveToNext(response);
      }
      return response;
    } catch (error) {
      if (onFailure) {
        onFailure(error.message);
      }
      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

//  user verification via link
export const userVerificationViaLink = createAsyncThunk(
  "auth/userVerificationViaLink",
  async ({ payload, moveToNext, onFailure }, thunkAPI) => {
    console.log("user", payload);
    try {
      const response = await userVerificationViaEmail(payload);
      if (moveToNext) {
        moveToNext(response);
      }
      return response;
    } catch (error) {
      if (onFailure) {
        onFailure(error.message);
      }
      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

//  get behaviour question
export const getBehaviorQuestionsSlice = createAsyncThunk(
  "auth/getBehaviorQuestionsSlice",
  async ({ onFailure }, thunkAPI) => {
    try {
      const response = await getBehaviorQuestions();
      return response;
    } catch (error) {
      if (onFailure) {
        onFailure(error.message);
      }
      if (error?.message === "Request failed with status code 403") {
        localStorage.removeItem("serviceToken");
        localStorage.removeItem("my_finance_user");
        window.location.replace("/");
      }

      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

//save behaviour question
export const saveUserBehaviorQuestionsSlice = createAsyncThunk(
  "auth/saveUserBehaviorQuestions",
  async ({ userBehaviourQuestions, moveToNext, onFailure }, thunkAPI) => {
    console.log("userBehaviourQuestions>>>>>", userBehaviourQuestions);
    try {
      const response = await saveUserBehaviorQuestions(userBehaviourQuestions);
      if (moveToNext) {
        moveToNext(response);
      }
      return response;
    } catch (error) {
      if (onFailure) {
        onFailure(error.message);
      }
      if (error?.message === "Request failed with status code 403") {
        localStorage.removeItem("serviceToken");
        localStorage.removeItem("my_finance_user");
        window.location.replace("/");
      }
      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

//  get user security question
export const getSecurityQuestionsSlice = createAsyncThunk(
  "auth/getSecurityQuestionsSlice",
  async ({ payload, moveToNext, onFailure }, thunkAPI) => {
    console.log("user", payload);
    try {
      const response = await getSecurityQuestions(payload);
      if (moveToNext) {
        moveToNext(response);
      }
      return response;
    } catch (error) {
      if (onFailure) {
        onFailure(error.message);
      }
      if (error?.message === "Request failed with status code 403") {
        localStorage.removeItem("serviceToken");
        localStorage.removeItem("my_finance_user");
        window.location.replace("/");
      }
      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

//save security question
export const saveSecurityQuestionsSlice = createAsyncThunk(
  "auth/saveSecurityQuestions",
  async ({ userSecurityQuestions, moveToNext, onFailure }, thunkAPI) => {
    console.log("userSecurityQuestions", userSecurityQuestions);
    try {
      const response = await saveSecurityQuestions(userSecurityQuestions);
      if (moveToNext) {
        moveToNext(response);
      }
      return response;
    } catch (error) {
      if (onFailure) {
        onFailure(error.message);
      }
      if (error?.message === "Request failed with status code 403") {
        localStorage.removeItem("serviceToken");
        localStorage.removeItem("my_finance_user");
        window.location.replace("/");
      }
      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

//update user profile
export const updateUserProfileSlice = createAsyncThunk(
  "auth/updateUserProfileSlice",
  async ({ payload, userId, moveToNext, onFailure }, thunkAPI) => {
    debugger;
    try {
      const response = await updateUserProfile(payload, userId);
      console.log("response", response?.data);
      if (moveToNext) {
        moveToNext(response);
      }
      return response;
    } catch (error) {
      if (onFailure) {
        onFailure(error.message);
      }
      if (error?.message === "Request failed with status code 403") {
        localStorage.removeItem("serviceToken");
        localStorage.removeItem("my_finance_user");
        window.location.replace("/");
      }
      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

// Login user
export const login = createAsyncThunk(
  "auth/login",
  async ({ user, moveToNext, onFailure }, thunkAPI) => {
    try {
      const response = await userLogin(user);
      // if (moveToNext) {
      //   moveToNext(response);
      // }
      // console.log("not error runing.....");

      return {
        ...response?.data?.userDetail,
        accessToken: response?.data?.accessToken,
        refreshToken: response?.data?.refreshToken,
      };
    } catch (error) {
      console.log("error runing.....,error", error);
      if (onFailure) {
        onFailure(error?.message);
      }

      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

// refreshTokenSlice
// export const refreshTokenSlice = createAsyncThunk(
//   "auth/refreshToken",
//   async (_, thunkAPI) => {
//     try {
//       const response = await refreshToken();
//       thunkAPI.dispatch(setAccessToken(response.data.accessToken));

//       return response.data.accessToken;
//     } catch (error) {
//       console.log("error runing.....");
//       if (onFailure) {
//         onFailure(error?.message);
//       }

//       return thunkAPI.rejectWithValue(error?.message);
//     }
//   }
// );

// change password
export const changePasswordRequestSlice = createAsyncThunk(
  "user/change-password",
  async ({ payload, moveToNext, onFailure }, thunkAPI) => {
    try {
      const response = await changePasswordRequest(payload);
      console.log("response::::", response);
      if (moveToNext) moveToNext(response?.data);
      return response;
    } catch (error) {
      if (onFailure) {
        onFailure(error?.message);
      }
      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

// reset password
export const resetPasswordSlice = createAsyncThunk(
  "user/reset-password",
  async ({ payload, moveToNext, onFailure }, thunkAPI) => {
    try {
      const response = await resetPasswordService(payload);
      if (moveToNext) moveToNext(response);
      return response;
    } catch (error) {
      if (onFailure) {
        onFailure(error?.message);
      }
      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
      state.isLoggedIn = true;
    },

    setAccessToken: (state, action) => {
      state.accessToken = action.payload;
    },
    logoutSlice: (state) => {
      state.isLoginSuccess = false;
      state.isLoggedIn = false;
      state.user = null;
      state.isSuccess = false;
    },
    reset: (state) => {
      state.isLoading = false;
      state.isLoginSuccess = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(userSignUpWithEmail.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(userSignUpWithEmail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(userSignUpWithEmail.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(userVerificationViaLink.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(userVerificationViaLink.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(userVerificationViaLink.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getBehaviorQuestionsSlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(getBehaviorQuestionsSlice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.behaviorQuestions = action.payload;
      })
      .addCase(getBehaviorQuestionsSlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
        state.behaviorQuestions = [];
      })

      .addCase(saveUserBehaviorQuestionsSlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(saveUserBehaviorQuestionsSlice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(saveUserBehaviorQuestionsSlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getSecurityQuestionsSlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(getSecurityQuestionsSlice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.securityQuestions = action.payload;
      })
      .addCase(getSecurityQuestionsSlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
        state.securityQuestions = [];
      })

      .addCase(saveSecurityQuestionsSlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(saveSecurityQuestionsSlice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(saveSecurityQuestionsSlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(updateUserProfileSlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(updateUserProfileSlice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(updateUserProfileSlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(login.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
        state.isLoginSuccess = false;
        state.isLoggedIn = false;
        state.user = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        console.log("action.payload");
        state.isLoading = false;
        state.isLoginSuccess = true;
        state.isSuccess = true;
        state.isError = false;
        state.isTwoFactor = action?.payload?.is_two_factor_enabled;
        state.isLoggedIn = true;
        state.user = action.payload;
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isLoginSuccess = false;
        state.isError = true;
        state.message = action.payload;
        state.user = null;
      })

      .addCase(changePasswordRequestSlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isLoginSuccess = false;
        state.isError = false;
        state.message = "";
        state.user = null;
      })
      .addCase(changePasswordRequestSlice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isLoginSuccess = false;
        state.isError = false;
        state.message = "";
        state.user = null;
      })
      .addCase(changePasswordRequestSlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isLoginSuccess = false;
        state.isError = true;
        state.user = null;
        state.message = action.payload;
      })

      .addCase(resetPasswordSlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isLoginSuccess = false;
        state.isError = false;
        state.message = "";
        state.user = null;
      })
      .addCase(resetPasswordSlice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isLoginSuccess = false;
        state.isError = false;
        state.message = "";
        state.user = null;
      })
      .addCase(resetPasswordSlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isLoginSuccess = false;
        state.isError = true;
        state.user = null;
        state.message = action.payload;
      });
  },
});

export const { setUser, reset, logoutSlice } = authSlice.actions;

export default authSlice.reducer;
