import { lazy } from "react";

// project imports
import GuestGuard from "utils/route-guard/GuestGuard";
import MinimalLayout from "layout/MinimalLayout";
import NavMotion from "layout/NavMotion";
import Loadable from "ui-component/Loadable";

// login routing
const AuthLogin = Loadable(
  lazy(() => import("views/pages/authentication/authentication3/Login3"))
);
const AuthRegister = Loadable(
  lazy(() => import("views/pages/authentication/authentication3/Register3"))
);
const AuthForgotPassword = Loadable(
  lazy(() =>
    import("views/pages/authentication/authentication3/ForgotPassword3")
  )
);
const OnBoard = Loadable(lazy(() => import("views/pages/onBoard")));
const Questionare = Loadable(lazy(() => import("views/pages/questionare")));
const QuestionareProfile = Loadable(
  lazy(() => import("views/pages/questionare/profileScreen"))
);
const AuthSetup = Loadable(lazy(() => import("views/pages/authSetup")));
const AuthQuestion = Loadable(lazy(() => import("views/pages/authQuestion")));
const PrivacyPolicy = Loadable(lazy(() => import("views/pages/privacyPolicy")));
const PublicPrivacyPolicy = Loadable(
  lazy(() => import("views/pages/privacyPolicy/publicPrivacyPolicy"))
);
const PublicTermsAndCondition = Loadable(
  lazy(() => import("views/pages/TermsAndContion/publicTermsAndCondition"))
);
const PickProfilePicture = Loadable(
  lazy(() => import("views/pages/pickProfilePicture"))
);
// const EmailVerification = Loadable(
//   lazy(() => import("views/pages/emailVerification"))
// );
// ==============================|| AUTH ROUTING ||============================== //

const ModerateRoute = {
  path: "/",
  element: (
    <NavMotion>
      <MinimalLayout />
    </NavMotion>
  ),
  children: [
    // {
    //   path: "/email-verification",
    //   element: <EmailVerification />,
    // },
    {
      path: "/on-board/:id/:otp",
      element: <OnBoard />,
    },
    {
      path: "/questionare",
      element: <QuestionareProfile />,
    },

    {
      path: "/auth-setup",
      element: <AuthSetup />,
    },

    {
      path: "/pick-profile-picture",
      element: <PickProfilePicture />,
    },

    {
      path: "/auth-questions",
      element: <AuthQuestion />,
    },
    {
      path: "/privacy-policy",
      element: <PrivacyPolicy />,
    },
    {
      path: "/privacy-and-policy",
      element: <PublicPrivacyPolicy />,
    },
    {
      path: "/terms-and-condition",
      element: <PublicTermsAndCondition />,
    },

    {
      path: "/forgot",
      element: <AuthForgotPassword />,
    },
  ],
};

export default ModerateRoute;
