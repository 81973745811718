import { memo, useMemo } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  ButtonBase,
  ListItemIcon,
  ListItemButton,
  ListItemText,
  Box,
  Typography,
  Drawer,
  Stack,
  useMediaQuery,
} from "@mui/material";
import Button from "views/ui-elements/basic/Button";

import PerfectScrollbar from "react-perfect-scrollbar";

// project imports
import MenuList from "../MenuList";
import LogoSection from "../LogoSection";
import MiniDrawerStyled from "./MiniDrawerStyled";
import Chip from "ui-component/extended/Chip";

import LAYOUT_CONST from "constant";
import useConfig from "hooks/useConfig";
import { drawerWidth } from "store/constant";

import { useDispatch, useSelector } from "store";
import { openDrawer } from "store/slices/menu";
import WalletIcon from "@mui/icons-material/Wallet";
import useScreenWidth from "hooks/useScreenWidth";
import useAuth from "hooks/useAuth";

// ==============================|| SIDEBAR DRAWER ||============================== //

const Sidebar = () => {
  const theme = useTheme();
  const { logout } = useAuth();
  const matchUpMd = useMediaQuery(theme.breakpoints.up("md"));
  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));

  const dispatch = useDispatch();
  const { drawerOpen } = useSelector((state) => state.menu);

  const { layout, drawerType } = useConfig();

  const logo = useMemo(
    () => (
      <Box sx={{ display: "flex", p: 2, mx: "auto" }}>
        <LogoSection />
      </Box>
    ),
    []
  );

  const handleLogout = async () => {
    try {
      await logout();
    } catch (err) {
      console.error(err);
    }
  };

  const BUTTON_PROPS = {
    variant: "contain",
    bg: "#0C7076",
    color: "#fff",
    radius: "4px",
    width: "100%",

    height: "35px",
    sx: {
      alignItems: "center",
      display: "flex",

      fontWeight: "500",
      fontSize: "16px",
    },
  };

  const drawerContent = (
    <>
      <MenuList />
      {/* <Button
        {...BUTTON_PROPS}
        sx={{ ...BUTTON_PROPS.sx }}
        onClick={() => handleLogout()}
      >
        Logout
      </Button> */}
      {/* <ListItemButton
                component=""
                to=""
                target=""
                disabled=""
                disableRipple=""


            >
                <ButtonBase aria-label="theme-icon" >
                    <ListItemIcon

                    >
                        <WalletIcon />
                    </ListItemIcon>
                </ButtonBase>

                <ListItemText
                    primary={
                        <Typography variant="h5" color="inherit">
                            Mudassar
                        </Typography>
                    }
                />



            </ListItemButton> */}

      {layout === LAYOUT_CONST.VERTICAL_LAYOUT && drawerOpen && " "}
      {layout === LAYOUT_CONST.VERTICAL_LAYOUT && drawerOpen && (
        <Stack direction="row" justifyContent="center" sx={{ mb: 2 }}></Stack>
      )}
    </>
  );

  const drawerSX = {
    paddingLeft: drawerOpen ? "16px" : 0,
    paddingRight: drawerOpen ? "16px" : 0,
    marginTop: drawerOpen ? 0 : "20px",
  };

  const drawer = useMemo(
    () => (
      <>
        {matchDownMd ? (
          <Box sx={drawerSX}>{drawerContent}</Box>
        ) : (
          <PerfectScrollbar
            component="div"
            style={{
              height: !matchUpMd ? "calc(100vh - 56px)" : "calc(100vh - 88px)",
              ...drawerSX,
            }}
          >
            {drawerContent}
          </PerfectScrollbar>
        )}
      </>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [matchUpMd, drawerOpen, drawerType]
  );

  const screenWidth = useScreenWidth();

  return (
    <Box
      component="nav"
      sx={{ flexShrink: { md: 0 }, width: matchUpMd ? drawerWidth : "auto" }}
      aria-label="mailbox folders"
    >
      {matchDownMd ||
      (drawerType === LAYOUT_CONST.MINI_DRAWER && drawerOpen) ? (
        <Drawer
          variant={matchUpMd ? "persistent" : "temporary"}
          anchor="left"
          open={screenWidth < 481 ? false : drawerOpen}
          onClose={() => dispatch(openDrawer(!drawerOpen))}
          sx={{
            "& .MuiDrawer-paper": {
              mt: matchDownMd ? 0 : 11,
              zIndex: 1099,
              width: drawerWidth,
              background: theme.palette.background.default,
              color: theme.palette.text.primary,
              borderRight: "none",
            },
          }}
          ModalProps={{ keepMounted: true }}
          color="inherit"
        >
          {matchDownMd && logo}

          {drawer}
        </Drawer>
      ) : (
        <MiniDrawerStyled variant="permanent" open={drawerOpen}>
          {logo}
          {drawer}
        </MiniDrawerStyled>
      )}
    </Box>
  );
};

export default memo(Sidebar);
