// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ``, "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paper": `#ffffff`,
	"primaryLight": `#6DA5C0`,
	"primary200": `#90caf9`,
	"primaryMain": `#0C7076`,
	"primaryDark": `#072E33`,
	"primary800": `#1565c0`,
	"secondaryLight": `#6DA5C0`,
	"secondary200": `#b39ddb`,
	"secondaryMain": `#0C7076`,
	"secondaryDark": `#072E33`,
	"secondary800": `#4527a0`,
	"secondaryGrey": `#fff`,
	"successLight": `#b9f6ca`,
	"success200": `#69f0ae`,
	"successMain": `#00e676`,
	"successDark": `#00c853`,
	"errorLight": `#ef9a9a`,
	"errorMain": `#f44336`,
	"errorDark": `#c62828`,
	"orangeLight": `#fbe9e7`,
	"orangeMain": `#ffab91`,
	"orangeDark": `#d84315`,
	"warningLight": `#fff8e1`,
	"warningMain": `rgba(0, 137, 123, 0.2196078431)`,
	"warningDark": `#ffc107`,
	"grey50": `#f8fafc`,
	"grey100": `#eef2f6`,
	"grey200": `#e3e8ef`,
	"grey300": `#cdd5df`,
	"grey500": `#4b5565`,
	"grey700": `#364152`,
	"grey900": `#121926`,
	"darkPaper": `#000`,
	"darkBackground": `#000`,
	"darkLevel1": `rgba(31, 34, 39, 0.75)`,
	"darkLevel2": `rgba(31, 34, 39, 0.75)`,
	"darkTextTitle": `#fff`,
	"darkTextPrimary": `#fff`,
	"darkTextSecondary": `#fff`,
	"darkPrimaryLight": `#fff`,
	"darkPrimaryMain": `#1565c0`,
	"darkPrimaryDark": `#1565c0`,
	"darkPrimary200": `#90caf9`,
	"darkPrimary800": `#1565c0`,
	"darkSecondaryLight": `#6DA5C0`,
	"darkSecondaryMain": `#0C7076`,
	"darkSecondaryDark": `#072E33`,
	"darkSecondary200": `#6DA5C0`,
	"darkSecondary800": `#0C7076`,
	"darkSecondaryGrey": `#fff`
};
export default ___CSS_LOADER_EXPORT___;
