import API from "../../../utilities/API";
// import { API_PATH } from "src/common/constants";

export const uploadPictureService = async (payload) => {
  const url = "/api/v1/gallery/upload";
  const response = await API.post(url, payload);
  // if (response.data.data) {
  //   const user = response.data.data;
  //   API.defaults.headers.Authorization = `Bearer ${user.token}`;
  //   delete user.moduleList;
  // }

  return response.data;
};

export const getGalleyPictures = async (systemIcons) => {
  const url = `/api/v1/gallery/download?storageType=${systemIcons}`;
  const response = await API.get(url);

  return response.data;

  return response.data;
};

export const deleteGalleyPictures = async (fileId) => {
  const url = `/api/v1/gallery/delete?fileIds=${fileId}`;
  const response = await API.delete(url);

  return response.data;
};
