import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getCreditCardList,
  getCreditCardDetails,
  createCreditCard,
  updateCreditCard,
  deleteCreditCard,
} from "../creditCardServices/creditServices";

const initialState = {
  singleCreditCardDetails: [],
  creditCardLists: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const getCreditCardSlice = createAsyncThunk(
  "creditCard/getCreditCardList",
  async ({ moveToNext, onFailure }, thunkAPI) => {
    try {
      const response = await getCreditCardList();
      if (moveToNext) {
        moveToNext(response);
      }
      return response?.data;
    } catch (error) {
      if (onFailure) {
        onFailure(error.message);
      }

      if (error?.message === "Request failed with status code 403") {
        localStorage.removeItem("serviceToken");
        localStorage.removeItem("my_finance_user");
        window.location.replace("/");
      }

      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const getCreditCardDetailsSlice = createAsyncThunk(
  "creditCard/getCreditCardDetails",
  async ({ cardId, moveToNext, onFailure }, thunkAPI) => {
    console.log("cardId", cardId);
    try {
      const response = await getCreditCardDetails(cardId);
      if (moveToNext) {
        moveToNext(response?.data);
      }
      console.log("response?.data", response?.data);
      return response?.data;
    } catch (error) {
      if (onFailure) {
        onFailure(error.message);
      }

      if (error?.message === "Request failed with status code 403") {
        localStorage.removeItem("serviceToken");
        localStorage.removeItem("my_finance_user");
        window.location.replace("/");
      }

      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const createCreditCardSlice = createAsyncThunk(
  "creditCard/createCreditCard",
  async ({ payload, moveToNext, onFailure }, thunkAPI) => {
    try {
      const response = await createCreditCard(payload);
      if (moveToNext) {
        moveToNext(response?.data);
      }
      return response;
    } catch (error) {
      if (onFailure) {
        onFailure(error.message);
      }
      if (error?.message === "Request failed with status code 403") {
        localStorage.removeItem("serviceToken");
        localStorage.removeItem("my_finance_user");
        window.location.replace("/");
      }

      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const updateCreditCardSlice = createAsyncThunk(
  "creditCard/updateCreditCard",
  async ({ creditCardId, updatePayload, moveToNext, onFailure }, thunkAPI) => {
    try {
      const response = await updateCreditCard(creditCardId, updatePayload);

      if (moveToNext) {
        moveToNext(response?.data);
      }
      return response?.data;
    } catch (error) {
      if (onFailure) {
        onFailure(error.message);
      }

      if (error?.message === "Request failed with status code 403") {
        localStorage.removeItem("serviceToken");
        localStorage.removeItem("my_finance_user");
        window.location.replace("/");
      }

      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const deleteCreditCardSlice = createAsyncThunk(
  "creditCard/deleteCreditCard",
  async ({ creditCardId, moveToNext, onFailure }, thunkAPI) => {
    try {
      const response = await deleteCreditCard(creditCardId);
      if (moveToNext) {
        moveToNext(response);
      }
      return response;
    } catch (error) {
      if (onFailure) {
        onFailure(error.message);
      }

      if (error?.message === "Request failed with status code 403") {
        localStorage.removeItem("serviceToken");
        localStorage.removeItem("my_finance_user");
        window.location.replace("/");
      }
      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const creditCardSlice = createSlice({
  name: "creditCard",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
      state.isLoggedIn = true;
    },
    logoutSlice: (state) => {
      state.isLoginSuccess = false;
      state.isLoggedIn = false;
      state.user = null;
      state.isSuccess = false;
    },
    reset: (state) => {
      state.isLoading = false;
      state.isLoginSuccess = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getCreditCardSlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(getCreditCardSlice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.creditCardLists = action.payload;
        state.singleCreditCardDetails = null;
      })
      .addCase(getCreditCardSlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getCreditCardDetailsSlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(getCreditCardDetailsSlice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.singleCreditCardDetails = action.payload;
      })
      .addCase(getCreditCardDetailsSlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
        state.singleCreditCardDetails = null;
      })

      .addCase(createCreditCardSlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(createCreditCardSlice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(createCreditCardSlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(updateCreditCardSlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(updateCreditCardSlice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(updateCreditCardSlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(deleteCreditCardSlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(deleteCreditCardSlice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(deleteCreditCardSlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { setUser, reset, logoutSlice } = creditCardSlice.actions;

export default creditCardSlice.reducer;
