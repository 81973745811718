import API from "../../../utilities/API";

export const getAccountsList = async (accountTypeId) => {
  // const url = `/api/v1/account?accountTypeId=${accountTypeId}`;

  let url = null;
  if (accountTypeId) {
    url = `/api/v1/account?accountTypeId=${accountTypeId}`;
  } else {
    url = `/api/v1/account`;
  }

  const response = await API.get(url);

  return response.data;
};

export const getAccountDetails = async (accountId) => {
  const url = `/api/v1/account/${accountId}`;
  const response = await API.get(url);

  return response.data;
};

export const createAccount = async (payload) => {
  const url = "/api/v1/account";
  const response = await API.post(url, payload);

  return response.data;
};

export const updateAccount = async (accountId, updatePayload) => {
  const url = `/api/v1/account//${accountId}`;
  const response = await API.put(url, updatePayload);

  return response.data;
};

export const deleteAccount = async (accountId) => {
  const url = `/api/v1/account/${accountId}`;
  const response = await API.delete(url);

  return response.data;
};

export const getCardTypeList = async () => {
  const url = `/api/v1/account/accountType`;
  const response = await API.get(url);

  return response.data;
};

export const getCashAccountGraphDetails = async (
  accountId,
  fromDate,
  toDate
) => {
  const url = `/api/v1/transactionLog?accountId=${accountId}&fromDate=${fromDate}&toDate=${toDate}`;
  const response = await API.get(url);

  return response.data;
};
export const getAccountTransactionDetails = async (
  accountId,
  fromDate,
  toDate
) => {
  let url = null;
  if (fromDate && toDate) {
    url = `/api/v1/account/${accountId}/transactionDetails?fromDate=${fromDate}&toDate=${toDate}`;
  } else {
    url = `/api/v1/account/${accountId}/transactionDetails`;
  }
  const response = await API.get(url);
  return response.data;
};
