import { useMemo } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import { useFormControl } from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import TextField from "@mui/material/TextField";

function InputField({
  placeholder = "Placeholder",
  id = "",
  type = "text",
  hasHelperText = false,
  helperText = "Helper Text",
  color = "secondary",
  ...props
}) {
  const { InputProps, ...restProps } = props;

  return (
    <>
      {/* <OutlinedInput
        label="Usama"
        placeholder={placeholder}
        id={id}
        type={type}
        onChange={props.onChange}
        onBlur={props.onBlur}
        inputProps={{
          style: CUSTOM_STYLES,
          ...InputProps,
        }}
        {...restProps}
      /> */}

      {/* {hasHelperText && <MyFormHelperText helperText={helperText} />} */}
      <TextField
        id={id}
        type={type}
        onChange={props.onChange}
        placeholder={placeholder}
        onBlur={props.onBlur}
        color={color}
        {...restProps}
      />
    </>
  );
}

function MyFormHelperText({ helperText }) {
  const { focused } = useFormControl() || {};

  helperText = useMemo(() => {
    if (focused) {
      return "This field is being focused";
    }
    return helperText || "Helper Text Here";
  }, [focused, helperText]);

  return <FormHelperText>{helperText}</FormHelperText>;
}

export default InputField;
