import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getTransactionReport } from "../ReportsServices/reportsServices";

const initialState = {
  reportDetails: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const getTransactionReportSlice = createAsyncThunk(
  "report/getTransactionReport",
  async ({ queryParams, moveToNext, onFailure }, thunkAPI) => {
    console.log(":::::::queryParams:::::::::", queryParams);
    try {
      const response = await getTransactionReport(queryParams);
      if (moveToNext) {
        moveToNext(response);
      }
      return response?.data;
    } catch (error) {
      if (onFailure) {
        onFailure(error.message);
      }
      if (error?.message === "Request failed with status code 403") {
        localStorage.removeItem("serviceToken");
        localStorage.removeItem("my_finance_user");
        window.location.replace("/");
      }
      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const reportSlice = createSlice({
  name: "report",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isLoginSuccess = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getTransactionReportSlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
        state.reportDetails = [];
      })
      .addCase(getTransactionReportSlice.fulfilled, (state, action) => {
        console.log("action:::::::::::>>>>", action.payload);
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.reportDetails = action.payload;
      })
      .addCase(getTransactionReportSlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { setUser, reset, logoutSlice } = reportSlice.actions;

export default reportSlice.reducer;
