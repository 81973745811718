import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Chip,
  ClickAwayListener,
  Divider,
  Grid,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  OutlinedInput,
  Paper,
  Popper,
  Stack,
  Switch,
  Typography,
} from "@mui/material";

// third-party
import { FormattedMessage } from "react-intl";
import PerfectScrollbar from "react-perfect-scrollbar";
import ProfilImage from "assets/images/profile.png";

// project imports
import MainCard from "ui-component/cards/MainCard";
import Transitions from "ui-component/extended/Transitions";
import UpgradePlanCard from "./UpgradePlanCard";
import useAuth from "hooks/useAuth";
import User1 from "assets/images/users/user-round.svg";
import User from "assets/images/icons/Grey/user.svg";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CategoryIcon from "@mui/icons-material/Category";
import Notification from "assets/images/icons/Grey/notification.svg";
import MenuIcon from "@mui/icons-material/Menu";
import Subscription from "assets/images/icons/Grey/premium.svg";
import QR from "assets/images/icons/Grey/qr.svg";
import Privacy from "assets/images/icons/Grey/security-safe.svg";
import XIcon from "assets/images/X-iconn.svg";
import Wallets from "assets/images/icons/Grey/wallet.svg";
import ViewComfyIcon from "@mui/icons-material/ViewComfy";
import LockIcon from "@mui/icons-material/Lock";

// assets
import {
  IconWallet,
  IconShare,
  IconLogout,
  IconSearch,
  IconSettings,
  IconUser,
} from "@tabler/icons";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import useConfig from "hooks/useConfig";
import { useHistory } from "react-router-dom";
import { CURRENT_APP_VERSION } from "config";

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
  const theme = useTheme();
  const { borderRadius } = useConfig();
  const navigate = useNavigate();

  const [sdm, setSdm] = useState(true);
  const [value, setValue] = useState("");
  const [notification, setNotification] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const { logout, user } = useAuth();
  const [open, setOpen] = useState(false);

  /**
   * anchorRef is used on different components and specifying one type leads to other components throwing an error
   * */
  const anchorRef = useRef(null);
  const handleLogout = async () => {
    localStorage.removeItem("serviceToken");
    localStorage.removeItem("my_finance_user");
    window.location.replace("/");
    // try {
    //   await logout();

    // } catch (err) {
    //   console.error(err);
    // }
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  const handleListItemClick = (event, index, route = "") => {
    setSelectedIndex(index);
    handleClose(event);

    if (route && route !== "") {
      navigate(route);
    }
  };
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const shareAppHandler = async () => {
    // try {
    //   if (navigator.share) {
    //     await navigator.share({
    //       title: "Tiitle",
    //       url: "www.google.com",
    //     });
    //     console.log("Shared successfully");
    //   } else {
    //     console.log("Web Share API not supported");
    //   }
    // } catch (error) {
    //   console.error("Error sharing:", error);
    // }
  };

  let my_finance_user_in_strng = localStorage.getItem("my_finance_user");
  let my_finance_user_in_Json = JSON.parse(my_finance_user_in_strng);

  return (
    <>
      <Chip
        icon={
          <Avatar
            src={user?.profilePhoto?.url || ProfilImage}
            sx={{
              ...theme.typography.mediumAvatar,
              backgroundColor: "#00897b !important",
              margin: "8px 0 8px 8px !important",
              cursor: "pointer",
              // background: "none",
              img: {
                width: user?.profilePhoto?.url ? "100%" : "22px",
                height: user?.profilePhoto?.url ? "100%" : "22px",
              },
            }}
            ref={anchorRef}
            aria-controls={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            alt="user-account"
          />
        }
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        sx={{ background: "none" }}
      />

      <Popper
        placement="bottom"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        modifiers={[
          {
            name: "offset",
            options: {
              offset: [0, 14],
            },
          },
        ]}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleClose}>
            <Transitions in={open} {...TransitionProps}>
              <Paper>
                {open && (
                  <MainCard
                    border={false}
                    elevation={16}
                    content={false}
                    boxShadow
                    shadow={theme.shadows[16]}
                  >
                    <Box
                      sx={{
                        p: 2,
                        pb: 0,
                        display: "flex ",
                        gap: 2,
                        marginY: 2,
                      }}
                    >
                      {/* <Grid container spacing={2}>
                        <Grid item xs={2}> */}
                      <Avatar
                        alt="Remy Sharp"
                        sx={{
                          backgroundColor: "#00897b !important",
                          img: {
                            width: user?.profilePhoto?.url ? "100%" : "20px",
                            height: user?.profilePhoto?.url ? "100%" : "20px",
                          },
                        }}
                        src={user?.profilePhoto?.url || ProfilImage}
                      />
                      {/* </Grid>
                        <Grid item xs={8}> */}
                      <div>
                        <Typography variant="h4" sx={{ display: "block" }}>
                          {user?.first_name || ""} {user?.last_name || ""}
                        </Typography>
                        <Typography variant="subtitle2">
                          {user?.email || ""}
                        </Typography>
                      </div>
                      {/* </Grid>
                      </Grid> */}
                      <br></br>
                      <Divider />
                    </Box>
                    <PerfectScrollbar
                      style={{
                        height: "100%",
                        maxHeight: "calc(100vh - 250px)",
                        overflowX: "hidden",
                      }}
                    >
                      <Box sx={{ p: 2, pt: 0 }}>
                        {my_finance_user_in_Json?.role_id === 2 && (
                          <>
                            <Box
                              bgcolor="#181a1e"
                              borderRadius="10px"
                              mt={1}
                              mx={1}
                            >
                              <List sx={{ padding: "0px" }}>
                                <ListItem
                                  disablePadding
                                  onClick={() =>
                                    navigate("/settings/edit-profile")
                                  }
                                  sx={{
                                    fontSize: "12px !important",
                                  }}
                                >
                                  <ListItemButton
                                    sx={{
                                      paddingTop: "8px",
                                      paddingBottom: "8px",
                                      fontSize: "12px !important",
                                    }}
                                  >
                                    <ListItemIcon>
                                      <img src={User} alt="" />
                                    </ListItemIcon>
                                    <ListItemText primary="MyInfo" />
                                  </ListItemButton>
                                </ListItem>
                                <ListItem
                                  sx={{
                                    border: "1px solid #8080801f",
                                    borderRight: "0px",
                                    borderLeft: "0px",
                                    borderBottom: "0px",
                                  }}
                                  disablePadding
                                  onClick={() => navigate("/my-wallets")}
                                >
                                  <ListItemButton
                                    sx={{
                                      paddingTop: "5px",
                                      paddingBottom: "5px",
                                    }}
                                  >
                                    <ListItemIcon>
                                      <img src={Wallets} alt="" />
                                    </ListItemIcon>
                                    <ListItemText
                                      primary="MyWallets"
                                      sx={{ fontSize: 16 }}
                                    />
                                  </ListItemButton>
                                </ListItem>
                                <ListItem
                                  disablePadding
                                  sx={{
                                    border: "1px solid #8080801f",
                                    borderRight: "0px",
                                    borderLeft: "0px",
                                    borderBottom: "0px",
                                  }}
                                  onClick={() => navigate("/notification-list")}
                                >
                                  <ListItemButton
                                    sx={{
                                      paddingTop: "5px",
                                      paddingBottom: "5px",
                                    }}
                                  >
                                    <ListItemIcon>
                                      {/* <img src={Notification} alt="" /> */}
                                      <MenuIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                      primary="MyTasks"
                                      sx={{ fontSize: 16 }}
                                    />
                                  </ListItemButton>
                                </ListItem>
                                <ListItem
                                  sx={{
                                    border: "1px solid #8080801f",
                                    borderRight: "0px",
                                    borderLeft: "0px",
                                    borderBottom: "0px",
                                  }}
                                  disablePadding
                                  onClick={() => navigate("/edit-category")}
                                >
                                  <ListItemButton
                                    sx={{
                                      paddingTop: "5px",
                                      paddingBottom: "5px",
                                    }}
                                  >
                                    <ListItemIcon>
                                      <CategoryIcon
                                        sx={{ color: "#fff", fontSize: "18px" }}
                                      />
                                    </ListItemIcon>
                                    <ListItemText
                                      primary="MyCategories"
                                      sx={{ fontSize: 16 }}
                                    />
                                  </ListItemButton>
                                </ListItem>

                                <ListItem
                                  sx={{
                                    border: "1px solid #8080801f",
                                    borderRight: "0px",
                                    borderLeft: "0px",
                                    borderBottom: "0px",
                                  }}
                                  disablePadding
                                  onClick={() => navigate("/customized-widget")}
                                >
                                  <ListItemButton
                                    sx={{
                                      paddingTop: "5px",
                                      paddingBottom: "5px",
                                    }}
                                  >
                                    <ListItemIcon>
                                      <ViewComfyIcon
                                        sx={{ color: "#fff", fontSize: "18px" }}
                                      />
                                    </ListItemIcon>
                                    <ListItemText
                                      primary="MyWidgets"
                                      sx={{ fontSize: 16 }}
                                    />
                                  </ListItemButton>
                                </ListItem>

                                {/* <ListItem
                                  sx={{
                                    border: "1px solid #8080801f",
                                    borderRight: "0px",
                                    borderLeft: "0px",
                                    borderBottom: "0px",
                                  }}
                                  disablePadding
                                  onClick={() => navigate("/my-subscription")}
                                >
                                  <ListItemButton
                                    sx={{
                                      paddingTop: "5px",
                                      paddingBottom: "5px",
                                    }}
                                  >
                                    <ListItemIcon>
                                      <img src={Subscription} alt="" />
                                    </ListItemIcon>
                                    <ListItemText
                                      primary="MySubscription"
                                      sx={{ fontSize: 16 }}
                                    />
                                  </ListItemButton>
                                </ListItem> */}
                              </List>
                            </Box>

                            <Box
                              bgcolor="#181a1e"
                              borderRadius="10px"
                              mx={1}
                              my={1}
                            >
                              <List sx={{ padding: "0px" }}>
                                <ListItem
                                  disablePadding
                                  onClick={() =>
                                    // navigate("/settings/privacy-policy")
                                    window.open(
                                      "https://twitter.com/messages/compose?recipient_id=1759557539543547904",
                                      "_blank"
                                    )
                                  }
                                  sx={{
                                    border: "1px solid #8080801f",
                                    borderRight: "0px",
                                    borderLeft: "0px",
                                    borderTop: "0px",
                                  }}
                                >
                                  <ListItemButton
                                    sx={{
                                      paddingTop: "5px",
                                      paddingBottom: "5px",
                                    }}
                                  >
                                    <ListItemIcon>
                                      <img width="20px" src={XIcon} alt="" />
                                    </ListItemIcon>
                                    <ListItemText
                                      primary="Contact Support "
                                      sx={{ fontSize: 16 }}
                                    />
                                  </ListItemButton>
                                </ListItem>

                                <ListItem
                                  disablePadding
                                  onClick={() =>
                                    navigate("/settings/terms-condition")
                                  }
                                >
                                  <ListItemButton
                                    sx={{
                                      paddingTop: "5px",
                                      paddingBottom: "5px",
                                    }}
                                  >
                                    <ListItemIcon>
                                      <img src={Privacy} alt="" />
                                    </ListItemIcon>
                                    <ListItemText
                                      primary="Terms & Condition"
                                      sx={{ fontSize: 16 }}
                                    />
                                  </ListItemButton>
                                </ListItem>

                                <ListItem
                                  disablePadding
                                  onClick={() =>
                                    navigate("/settings/privacy-policy")
                                  }
                                >
                                  <ListItemButton
                                    sx={{
                                      paddingTop: "5px",
                                      paddingBottom: "5px",
                                    }}
                                  >
                                    <ListItemIcon>
                                      <img src={Privacy} alt="" />
                                    </ListItemIcon>
                                    <ListItemText
                                      primary="Privacy Policy"
                                      sx={{ fontSize: 16 }}
                                    />
                                  </ListItemButton>
                                </ListItem>
                              </List>
                            </Box>
                          </>
                        )}
                        <Box
                          bgcolor="#181a1e"
                          borderRadius="10px"
                          mx={1}
                          my={1}
                        >
                          <List sx={{ padding: "0px" }}>
                            <ListItem
                              disablePadding
                              onClick={() => handleLogout()}
                            >
                              <ListItemButton
                                sx={{
                                  paddingTop: "5px",
                                  paddingBottom: "5px",
                                }}
                              >
                                <ListItemIcon>
                                  <LockIcon />
                                </ListItemIcon>
                                <ListItemText
                                  primary="Logout"
                                  sx={{ fontSize: 16 }}
                                />
                              </ListItemButton>
                            </ListItem>
                          </List>
                        </Box>
                        <Box textAlign="center">{CURRENT_APP_VERSION}</Box>
                        {/* {my_finance_user_in_Json?.role_id === 2 && (
                          <UpgradePlanCard />
                        )} */}
                      </Box>
                    </PerfectScrollbar>
                  </MainCard>
                )}
              </Paper>
            </Transitions>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  );
};

export default ProfileSection;
