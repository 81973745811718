import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Snackbar, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import LaptopIcon from "@mui/icons-material/Laptop";
import FolderCopyIcon from "@mui/icons-material/FolderCopy";
import CalculateIcon from "@mui/icons-material/Calculate";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

import { ReactComponent as DWalleticon } from "assets/images/myfinance/dwallets.svg";
import { ReactComponent as Reportsicon } from "assets/images/myfinance/reports.svg";
import { ReactComponent as Dashboardicon } from "assets/images/myfinance/dashboard.svg";
import { ReactComponent as Calculateicon } from "assets/images/myfinance/calculator.svg";
import { ReactComponent as AddLogIcon } from "assets/images/myfinance/addlog.svg";
import { useLocation, useNavigate, useNavigation } from "react-router-dom";

import { ReactComponent as SelectedDashboard } from "assets/images/icons/selectedBottom/activity.svg";
import { ReactComponent as SelectedCreditCard } from "assets/images/icons/selectedBottom/cards.svg";
import { ReactComponent as SelectedCalculator } from "assets/images/icons/selectedBottom/calculator.svg";
import { ReactComponent as SelectedReports } from "assets/images/icons/selectedBottom/chart.svg";
import io from "socket.io-client";
import OneSignal from "react-onesignal";
import { notificationNotify, notify } from "utils/helper";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import { NOTIFICATION_TYPE, NOTIFICATION_TYPE_NAME } from "../config";
import { getUserWalletListSlice } from "store/wallets/walletsSlice/wallets.slice";
import { useDispatch, useSelector } from "react-redux";
import useAuth from "hooks/useAuth";

const MobileScreenLayout = ({
  bottomBar = true,
  topBarShow,
  topBar,
  headerbgcolor,
  subTittle,
  children,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const serviceToken = window.localStorage.getItem("serviceToken");
  console.log("serviceToken", serviceToken);

  let my_finance_user_in_strng = localStorage.getItem("my_finance_user");
  let my_finance_user_in_Json = JSON.parse(my_finance_user_in_strng);

  // useEffect(() => {
  //   // OneSignalDeferred.push((OneSignal) => {
  //   OneSignal.init({
  //     appId: "26a62eac-c97a-45d4-870c-e99ca31f19c3",
  //     safari_web_id: "web.onesignal.auto.5136aa20-d33c-4de6-8d7f-c3f8c4b264dc",
  //     allowLocalhostAsSecureOrigin: true,
  //     promptOptions: {
  //       slidedown: {
  //         prompts: [
  //           {
  //             type: "push", // Configure prompt for push notifications
  //             autoPrompt: true, // Automatically display the prompt
  //             text: {
  //               actionMessage:
  //                 "We'd like to show you notifications for the latest news and updates.",
  //               acceptButton: "Allow",
  //               cancelButton: "Cancel",
  //             },
  //             delay: {
  //               pageViews: 1, // Prompt after 1 page view
  //               timeDelay: 3, // Prompt after 3 seconds
  //             },
  //           },
  //         ],
  //       },
  //     },
  //     welcomeNotification: {
  //       title: "One Signal",
  //       message: "Thanks for subscribing!",
  //     },
  //   }).then(() => {
  //     OneSignal.Slidedown.promptPush();
  //     /**Automatically subscribe to the new_app_version tag */

  //     OneSignal.User.addTag(
  //       "new_app_version",
  //       "new_app_version",
  //       (tagsSent) => {
  //         // Callback called when tag has finished sending
  //         console.log("new_app_version TAG SENT", tagsSent);
  //       }
  //     );
  //     OneSignal.User.addAlias("ALIAS_LABEL", "ALIAS_ID");

  //     // Handle user subscription changes
  //     OneSignal.User.PushSubscription.addEventListener("change", (event) => {
  //       if (event.current.optedIn) {
  //         console.log(
  //           "my_finance_user_in_Json?.id",
  //           my_finance_user_in_Json?.id
  //         );
  //         OneSignal.login(my_finance_user_in_Json?.id); // Login on subscription
  //       } else {
  //         OneSignal.logout(); // Logout on unsubscription
  //       }
  //       console.log(
  //         `The push subscription has changed!`,
  //         event.current.optedIn
  //       );
  //     });

  //     // Handle user subscription changes
  //     OneSignal.Notifications.addEventListener("eventname", (event) => {
  //       console.log(`The push subscription has changed!`, event.current);
  //     });

  //     // Request user permission (optional)
  //     OneSignal.promptForNotifications();
  //   });

  //   // });
  // }, []);

  const [socket, setSocket] = useState(null);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(true);
  const [notificationData, setNotificationData] = useState(null);
  const { userWallets, isError, isSuccess, isLoading, message } = useSelector(
    (state) => state.wallets
  );
  const { user } = useAuth();

  useEffect(() => {
    if (user?.id) {
      dispatch(getUserWalletListSlice({ userId: user?.id }));
    }
  }, [user]);

  // useEffect(() => {
  //   const newSocket = io("https://7dc4-94-207-116-107.ngrok-free.app/", {
  //     extraHeaders: {
  //       // Authorization: "Bearer authorization_token_here",
  //       "ngrok-skip-browser-warning": 69420,
  //     },
  //   });
  //   if (!newSocket) {
  //     setOpen(true);
  //     if (my_finance_user_in_Json?.id) {
  //       setSocket(newSocket);

  //       console.log("newSocket>>>>", newSocket);

  //       newSocket.on("connect", (data) => {
  //         console.log("my_finance_user_in_Json?.id", data);

  //         newSocket.emit("joinUser", {
  //           userId: `${my_finance_user_in_Json?.id}`,
  //         });

  //         console.log("connected runing.....");
  //       });

  //       newSocket.on("connected", (data) => {
  //         console.log("connected data:", data);
  //       });
  //       newSocket.on("disconnect", () => {
  //         console.log("Disconnected");
  //       });

  //       newSocket.on("connect_error", (err) => {
  //         console.log("connect_error", err); // prints the message associated with the error
  //       });

  //       newSocket.on("newNotification", (data) => {
  //         console.log("Received notification data:", data);

  //         // notificationNotify("Account updated Successfully");
  //         setNotificationData(data);
  //         setOpen(true);
  //       });
  //     }

  //     // Clean up connection on unmount
  //     return () => {
  //       if (socket) {
  //         console.log("socket>>>>>>>>", socket);
  //         socket.disconnect();
  //         // socket.off("connect");
  //         //     socket.off("connected");
  //         socket.off("disconnect");
  //         //     socket.off("messageData");
  //       }
  //     };
  //   }
  // }, []);

  // const disconnectHandler = () => {
  //   console.log("socket>>>>>>>>>>>", socket);
  //   socket.disconnect();
  // };

  return (
    <div className="negative-margin-40">
      {/* <Button onClick={disconnectHandler}>LOgout</Button> */}

      {notificationData && open && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={open}
          autoHideDuration={25000}
          onClose={() => setOpen(false)}
          message="Note archived"
          className="notification-popup"
          sx={{
            borderRadius: "12px",
          }}
        >
          <Box
            bgcolor="rgb(31, 34, 39, 1)"
            borderRadius="12px"
            display="flex"
            justifyContent="space-between"
            px={2}
            sx={{ cursor: "pointer", width: "330px" }}
            py={2.5}
            // onClick={() => updateNotification(id)}
          >
            <Box display="flex" className="position-relative" width="100%">
              <AccountBalanceIcon sx={{ color: "#00897b", fontSize: "30px" }} />

              <Box ml={2} sx={{ flex: 1 }}>
                <Typography
                  variant="h4"
                  component="h3"
                  color="#fff"
                  fontWeight="400"
                >
                  {notificationData?.notificationType ===
                    NOTIFICATION_TYPE.DAILY_REMINDER &&
                    NOTIFICATION_TYPE_NAME.DAILY_REMINDER}

                  {notificationData?.notificationType ===
                    NOTIFICATION_TYPE.GOAL_REMINDER &&
                    NOTIFICATION_TYPE_NAME.GOAL_REMINDER}

                  {notificationData?.notificationType ===
                    NOTIFICATION_TYPE.LOAN_REMINDER &&
                    NOTIFICATION_TYPE_NAME.LOAN_REMINDER}

                  {notificationData?.notificationType ===
                    NOTIFICATION_TYPE.PAYMENT_REMINDER &&
                    NOTIFICATION_TYPE_NAME.PAYMENT_REMINDER}

                  {notificationData?.notificationType ===
                    NOTIFICATION_TYPE.RESERVE_REMINDER &&
                    NOTIFICATION_TYPE_NAME.RESERVE_REMINDER}
                </Typography>
                <Typography
                  fontSize="10px"
                  variant="h4"
                  component="h3"
                  color="#CDC4C4"
                  fontWeight="400"
                  lineHeight="16px"
                >
                  {notificationData?.notificationContent || ""}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Snackbar>
      )}

      {my_finance_user_in_Json?.role_id === 2 && userWallets.length > 0 && (
        <Box
          position="fixed"
          bottom=" 30px"
          right="30px"
          width="60px"
          height="60px"
          bgcolor="#00897b"
          borderRadius="100px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          style={{
            cursor: "pointer",
          }}
          sx={{ display: { xs: "none", md: "flex" } }}
          onClick={() => navigate("/new-logs")}
        >
          <AddLogIcon />
        </Box>
      )}
      <Box
        bgcolor="#000"
        radius="5px"
        maxWidth="500px"
        // height="100vh"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        sx={{ borderRadius: "24px", margin: "auto" }}
        className="remove-scroll-lgscreen"
      >
        {(location.pathname !== "/splash-screen" ||
          location.pathname !== "/on-board") &&
          serviceToken && (
            <>
              {topBar ? (
                topBar
              ) : topBarShow === true ? (
                <TopNavigationBar
                  headerbgcolor={headerbgcolor}
                  subTittle={subTittle}
                  children={children}
                />
              ) : null}
            </>
          )}

        <Box sx={{ flex: 1, overflow: "auto" }}>{children}</Box>
        {location.pathname !== "/splash-screen" &&
          location.pathname !== "/on-board" &&
          serviceToken &&
          bottomBar && <BottomNavigationBar />}
      </Box>
    </div>
  );
};

export default MobileScreenLayout;

const BottomNavigationBar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  let my_finance_user_in_strng = localStorage.getItem("my_finance_user");
  let my_finance_user_in_Json = JSON.parse(my_finance_user_in_strng);

  const BOTTOM_BAR_ICON_PROPS = {
    fontSize: "30px",
    color: "#fff",
  };

  return (
    <Box
      sx={{ display: { xs: "flex", md: "none" } }}
      justifyContent="space-between"
      alignItems="flex-end"
      m={3}
      mt={0}
      pb={0}
      className="nav-bar"
    >
      {location.pathname === "/custom-dashboard" ? (
        <SelectedDashboard
          sx={BOTTOM_BAR_ICON_PROPS}
          onClick={() => navigate("/custom-dashboard")}
        />
      ) : (
        <Dashboardicon
          sx={BOTTOM_BAR_ICON_PROPS}
          onClick={() => navigate("/custom-dashboard")}
        />
      )}

      {location.pathname === "/home" ? (
        <SelectedCreditCard
          sx={BOTTOM_BAR_ICON_PROPS}
          onClick={() => navigate("/home")}
        />
      ) : (
        <DWalleticon
          sx={BOTTOM_BAR_ICON_PROPS}
          onClick={() => navigate("/home")}
        />
      )}
      {my_finance_user_in_Json?.role_id === 2 && (
        <AddLogIcon
          className="nav-add-button"
          sx={{ fontSize: "40px", color: "#00897b" }}
          onClick={() => navigate("/new-logs")}
        />
      )}

      {/* <Box
        className="nav-add-button"
        bgcolor="#00897b"
        width="57px"
        height="57px"
        borderRadius="100px"
        onClick={() => navigate("/new-logs")}
      >
        kvj
      </Box> */}

      {location.pathname === "/budget-dashbaord" ? (
        <SelectedCalculator
          sx={BOTTOM_BAR_ICON_PROPS}
          onClick={() => navigate("/budget-dashbaord")}
        />
      ) : (
        <Calculateicon
          sx={BOTTOM_BAR_ICON_PROPS}
          onClick={() => navigate("/budget-dashbaord")}
        />
      )}

      {location.pathname === "/reports" ? (
        <SelectedReports
          sx={BOTTOM_BAR_ICON_PROPS}
          onClick={() => navigate("/reports")}
        />
      ) : (
        <Reportsicon
          sx={BOTTOM_BAR_ICON_PROPS}
          onClick={() => navigate("/reports")}
        />
      )}
    </Box>
  );
};

const TopNavigationBar = ({ headerbgcolor, subTittle, children }) => {
  const HEADER_LEFT_RIGHT_CIRCLE = {
    width: "40px",
    height: "40px",
    bgcolor: "rgb(31, 34, 39, 0.75)",
    borderRadius: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <Box
      sx={{ display: { xs: "flex" } }}
      height="40px"
      // display="flex"
      alignItems="center"
      justifyContent="space-between"
      px={2}
      py={3}
      boxSizing="content-box"
      borderRadius="20px 20px 0px 0px"
      bgcolor={headerbgcolor ? headerbgcolor : "transparent"}
      className="mobile-header"
    >
      <Box {...HEADER_LEFT_RIGHT_CIRCLE}>
        <KeyboardArrowLeftIcon sx={{ fontSize: "30px", color: "#fff" }} />
      </Box>
      <div>
        <Typography
          textAlign="center"
          fontSize="18px"
          variant="h3"
          component="h6"
          color="#fff"
          fontWeight="500"
        >
          Accounts
        </Typography>
        {subTittle && (
          <Typography
            textAlign="center"
            fontSize="12px"
            variant="h3"
            component="h6"
            color="#fff"
            fontWeight="300"
          >
            {subTittle}
          </Typography>
        )}
      </div>

      <Box {...HEADER_LEFT_RIGHT_CIRCLE}>
        <AddIcon sx={{ fontSize: "25px", color: "#fff" }} />
      </Box>
    </Box>
  );
};
