import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getGoalList,
  getGoalDetails,
  createGoal,
  updateGoal,
  deleteGoal,
  getOverAllGoalDetails,
} from "../goalServices/goalServices";

const initialState = {
  singleGoalDetails: [],
  goalLists: [],
  overAllGoalDetails: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const getGoalListSlice = createAsyncThunk(
  "goal/getGoalList",
  async ({ queryParams, moveToNext, onFailure }, thunkAPI) => {
    try {
      const response = await getGoalList(queryParams);
      if (moveToNext) {
        moveToNext(response);
      }
      console.log("response?.data", response?.data?.Goal);
      return response?.data?.Goal;
    } catch (error) {
      if (onFailure) {
        onFailure(error.message);
      }

      if (error?.message === "Request failed with status code 403") {
        localStorage.removeItem("serviceToken");
        localStorage.removeItem("my_finance_user");
        window.location.replace("/");
      }

      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const getGoalDetailsSlice = createAsyncThunk(
  "goal/getGoalDetails",
  async ({ goalId, moveToNext, onFailure }, thunkAPI) => {
    console.log("goalId", goalId);
    try {
      const response = await getGoalDetails(goalId);
      if (moveToNext) {
        moveToNext(response?.data);
      }
      console.log("response?.data", response?.data);
      return response?.data;
    } catch (error) {
      if (onFailure) {
        onFailure(error.message);
      }

      if (error?.message === "Request failed with status code 403") {
        localStorage.removeItem("serviceToken");
        localStorage.removeItem("my_finance_user");
        window.location.replace("/");
      }

      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const createGoalSlice = createAsyncThunk(
  "goal/createGoal",
  async ({ payload, moveToNext, onFailure }, thunkAPI) => {
    try {
      const response = await createGoal(payload);
      if (moveToNext) {
        moveToNext(response?.data);
      }
      return response;
    } catch (error) {
      if (onFailure) {
        onFailure(error.message);
      }
      if (error?.message === "Request failed with status code 403") {
        localStorage.removeItem("serviceToken");
        localStorage.removeItem("my_finance_user");
        window.location.replace("/");
      }

      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const updateGoalSlice = createAsyncThunk(
  "goal/updateGoal",
  async ({ goalId, updatePayload, moveToNext, onFailure }, thunkAPI) => {
    try {
      const response = await updateGoal(goalId, updatePayload);

      let filterWallet =
        response &&
        response.data &&
        response.data.length > 0 &&
        response.data.find((item) => item.id === goalId);
      if (moveToNext) {
        moveToNext(filterWallet);
      }
      return response?.data;
    } catch (error) {
      if (onFailure) {
        onFailure(error.message);
      }

      if (error?.message === "Request failed with status code 403") {
        localStorage.removeItem("serviceToken");
        localStorage.removeItem("my_finance_user");
        window.location.replace("/");
      }

      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const deleteGoalSlice = createAsyncThunk(
  "goal/deleteGoal",
  async ({ goalId, moveToNext, onFailure }, thunkAPI) => {
    try {
      const response = await deleteGoal(goalId);
      if (moveToNext) {
        moveToNext(response);
      }
      return response;
    } catch (error) {
      if (onFailure) {
        onFailure(error.message);
      }

      if (error?.message === "Request failed with status code 403") {
        localStorage.removeItem("serviceToken");
        localStorage.removeItem("my_finance_user");
        window.location.replace("/");
      }
      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const getOverAllGoalDetailsSlice = createAsyncThunk(
  "goal/getOverAllGoalDetails",
  async ({ queryParams, moveToNext, onFailure }, thunkAPI) => {
    console.log("queryParams", queryParams);
    try {
      const response = await getOverAllGoalDetails(queryParams);
      if (moveToNext) {
        moveToNext({ ...response?.data });
      }
      return { ...response?.data };
    } catch (error) {
      if (onFailure) {
        onFailure(error.message);
      }

      if (error?.message === "Request failed with status code 403") {
        localStorage.removeItem("serviceToken");
        localStorage.removeItem("my_finance_user");
        window.location.replace("/");
      }

      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const goalSlice = createSlice({
  name: "goal",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
      state.isLoggedIn = true;
    },
    logoutSlice: (state) => {
      state.isLoginSuccess = false;
      state.isLoggedIn = false;
      state.user = null;
      state.isSuccess = false;
    },
    reset: (state) => {
      state.isLoading = false;
      state.isLoginSuccess = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getGoalListSlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(getGoalListSlice.fulfilled, (state, action) => {
        console.log("action.payload", action.payload);
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.goalLists = action.payload;
      })
      .addCase(getGoalListSlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getGoalDetailsSlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(getGoalDetailsSlice.fulfilled, (state, action) => {
        console.log("action.payload", action.payload);
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.singleGoalDetails = action.payload;
      })
      .addCase(getGoalDetailsSlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
        state.singleGoalDetails = null;
      })

      .addCase(createGoalSlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(createGoalSlice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(createGoalSlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(updateGoalSlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(updateGoalSlice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(updateGoalSlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(deleteGoalSlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(deleteGoalSlice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(deleteGoalSlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getOverAllGoalDetailsSlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
        state.singleGoalDetails = [];
      })
      .addCase(getOverAllGoalDetailsSlice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.singleGoalDetails = [];
        state.overAllGoalDetails = action.payload;
      })
      .addCase(getOverAllGoalDetailsSlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.singleGoalDetails = [];
        state.message = action.payload;
      });
  },
});

export const { setUser, reset, logoutSlice } = goalSlice.actions;

export default goalSlice.reducer;
