import React from "react";
import { Box, Chip, Typography } from "@mui/material";

import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import { useNavigate } from "react-router-dom";
import { LOG_TYPE } from "../../../config";
import { formatNumber, getTimeLabel } from "utils/helper";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

const HistoryItemCard = ({ transactionItem, tag, url, type }) => {
  const navigate = useNavigate();

  let my_finance_user_in_strng = localStorage.getItem("my_finance_user");
  let my_finance_user_in_Json = JSON.parse(my_finance_user_in_strng);

  return (
    <Box
      bgcolor="rgb(31, 34, 39, 0.75)"
      borderRadius="16px"
      // display="flex"
      justifyContent="space-between"
      mb={2}
      px={2}
      sx={{ cursor: "pointer" }}
      py={2}
      onClick={() =>
        navigate(url, {
          state: {
            transactionDetails: transactionItem,
          },
        })
      }
    >
      <Box display="flex" alignItems="center" width="100%">
        <Box
          width="40px"
          height="40px"
          bgcolor="#000"
          borderRadius="50px"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {/* <AccountBalanceIcon sx={{ color: "#fff", fontSize: "20px" }} /> */}

          <img
            width="21px"
            height="50px"
            className="icon-img-color-white"
            src={transactionItem?.category?.icon?.url}
            alt=""
          />
        </Box>

        <Box
          ml={3}
          // display="flex"
          // justifyContent="space-between"
          // alignItems="center"
          // height="100%"
          sx={{ flex: 1 }}
        >
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography
              fontSize="14px"
              variant="h4"
              component="h3"
              color="#fff"
              fontWeight="400"
              mb="0px"
              className="transaction-note"
            >
              {transactionItem.transactionType?.id === LOG_TYPE.INCOME && (
                <>
                  {transactionItem?.toAccount && <>{transactionItem?.notes}</>}
                  {transactionItem?.toCreditCard && (
                    <>{transactionItem?.notes}</>
                  )}
                  {transactionItem?.toGoal && <>{transactionItem?.notes}</>}
                  {transactionItem?.toLoan && <>{transactionItem?.notes}</>}
                  {transactionItem?.toReserve && <>{transactionItem?.notes}</>}
                </>
              )}
              {transactionItem.transactionType?.id === LOG_TYPE.EXPENSE && (
                <>
                  {transactionItem?.fromAccount && (
                    <>{transactionItem?.notes}</>
                  )}
                  {transactionItem?.fromCreditCard && (
                    <>{transactionItem?.notes}</>
                  )}
                  {transactionItem?.fromGoal && <>{transactionItem?.notes}</>}
                  {transactionItem?.fromLoan && <>{transactionItem?.notes}</>}
                  {transactionItem?.fromReserve && (
                    <>{transactionItem?.notes}</>
                  )}
                </>
              )}
              {transactionItem.transactionType?.id === LOG_TYPE.TRANSFER && (
                <>{transactionItem?.notes}</>
              )}
            </Typography>
            {transactionItem?.cover_payment !== null && (
              <Chip
                sx={{
                  height: "25px",
                  borderRadius: "5px",
                  fontSize: "10px",
                  fontWeight: "500",
                  color: "#fff",
                  backgroundColor: "rgb(0 137 123 / 46%)",

                  padding: "5px",
                }}
                label={`${
                  my_finance_user_in_Json &&
                  my_finance_user_in_Json.wallet &&
                  my_finance_user_in_Json.wallet.length > 0 &&
                  my_finance_user_in_Json?.wallet[0]?.currency?.symbol
                } ${formatNumber(transactionItem?.cover_payment)}`}
              />
            )}
          </div>

          <div
            style={{
              textAlign: "right",
              height: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-end",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <Typography
                fontSize="12px"
                variant="body2"
                component="h3"
                color="#fff"
                fontWeight="300"
                lineHeight="14px"
                mb="0px"
              >
                {/* ADCB */}

                {transactionItem?.transactionType?.id === LOG_TYPE.INCOME && (
                  <>
                    {transactionItem?.toAccount && (
                      <>{transactionItem?.toAccount?.name}</>
                    )}
                    {transactionItem?.toCreditCard && (
                      <>{transactionItem?.toCreditCard?.name}</>
                    )}
                    {transactionItem?.toGoal && (
                      <>{transactionItem?.toGoal?.name}</>
                    )}
                    {transactionItem?.toLoan && (
                      <>{transactionItem?.toLoan?.name}</>
                    )}
                    {transactionItem?.toReserve && (
                      <>{transactionItem?.toReserve?.name}</>
                    )}
                  </>
                )}
                {transactionItem?.transactionType?.id === LOG_TYPE.EXPENSE && (
                  <>
                    {transactionItem?.fromAccount && (
                      <>{transactionItem?.fromAccount?.name}</>
                    )}
                    {transactionItem?.fromCreditCard && (
                      <>{transactionItem?.fromCreditCard?.name}</>
                    )}
                    {transactionItem?.fromGoal && (
                      <>{transactionItem?.fromGoal?.name}</>
                    )}
                    {transactionItem?.fromLoan && (
                      <>{transactionItem?.fromLoan?.name}</>
                    )}
                    {transactionItem?.fromReserve && (
                      <>{transactionItem?.fromReserve?.name}</>
                    )}
                  </>
                )}
                {transactionItem.transactionType?.id === LOG_TYPE.TRANSFER && (
                  <>ADCB</>
                )}
              </Typography>
              <Typography
                color="#fff"
                fontSize="12px"
                variant="body2"
                component="h3"
                fontWeight="300"
                lineHeight="14px"
                textAlign="right"
                mb="0px"
                mt="0px"
                fontStyle="italic"
                display="flex"
                alignItems="center"
                gap="2px"
              >
                <AccessTimeIcon fontSize="12px" color="grey" />{" "}
                {getTimeLabel(transactionItem?.transaction_date)}
              </Typography>
            </Box>

            <Typography
              variant="body2"
              component="h3"
              color={
                transactionItem &&
                transactionItem.transactionType &&
                transactionItem.transactionType?.id === LOG_TYPE.INCOME
                  ? "#CAFFBF"
                  : transactionItem.transactionType?.id === LOG_TYPE.EXPENSE
                  ? "#FFADAD"
                  : transactionItem.transactionType?.id === LOG_TYPE.TRANSFER
                  ? "#fff"
                  : ""
              }
              mb="0px"
              fontSize="14px"
              fontWeight="500"
              position="relative"
              top="4px"
            >
              {transactionItem &&
              transactionItem.transactionType &&
              transactionItem.transactionType?.id === LOG_TYPE.INCOME
                ? "+"
                : transactionItem.transactionType?.id === LOG_TYPE.EXPENSE
                ? "-"
                : transactionItem.transactionType?.id === LOG_TYPE.TRANSFER
                ? "--"
                : ""}
              {`${
                my_finance_user_in_Json &&
                my_finance_user_in_Json.wallet &&
                my_finance_user_in_Json.wallet.length > 0 &&
                my_finance_user_in_Json?.wallet[0]?.currency?.symbol
              }${formatNumber(transactionItem?.transaction_amount)}`}
            </Typography>
          </div>
        </Box>
      </Box>
      {/* <Typography
        color="#00897b"
        fontSize="12px"
        variant="body2"
        component="h3"
        fontWeight="300"
        lineHeight="14px"
        textAlign="right"
        mb="0px"
        mt="10px"
      >
        {getTimeLabel(transactionItem?.transaction_date)}
      </Typography> */}
    </Box>
  );
};

export default HistoryItemCard;
