import API from "../../../utilities/API";

export const getReserveList = async () => {
  const url = `/api/v1/reserve`;
  const response = await API.get(url);

  return response.data;
};

export const getReserveDetails = async (reserveId, walletId) => {
  const url = `/api/v1/reserve?walletId=${walletId}`;
  const response = await API.get(url);

  return response.data;
};

export const createReserve = async (payload) => {
  const url = "/api/v1/reserve";
  const response = await API.post(url, payload);

  return response.data;
};

export const updateReserve = async (reserveId, updatePayload) => {
  const url = `/api/v1/reserve/${reserveId}`;
  const response = await API.put(url, updatePayload);

  return response.data;
};

export const deleteReserve = async (reserveId) => {
  const url = `/api/v1/reserve/${reserveId}`;
  const response = await API.delete(url);

  return response.data;
};
