import React, { useEffect } from "react";
import {
  Box,
  Typography,
  Tooltip,
  IconButton,
  FormHelperText,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import MyReserveIcon from "assets/images/icons/supporting_icons/my-reserve.svg";
import RecomendedIcon from "assets/images/icons/supporting_icons/recomended.svg";
import { IconInfoSquareRounded } from "@tabler/icons";
import { useDispatch, useSelector } from "react-redux";
import { getReserveDetailsSlice } from "store/reserve/reserveSlice/reserve.slice";
import { covertDigitIntoMonthsAndDays, formatNumber } from "utils/helper";

const ReserveCard = ({ iconUrl, tittle, route }) => {
  const { isLoading, isError, isSuccess, reserveDetails, message } =
    useSelector((state) => state.reserve);
  const dispatch = useDispatch();

  let my_finance_user_in_strng = localStorage.getItem("my_finance_user");
  let my_finance_user_in_Json = JSON.parse(my_finance_user_in_strng);

  useEffect(() => {
    let reserveId =
      my_finance_user_in_Json &&
      my_finance_user_in_Json.wallet &&
      my_finance_user_in_Json.wallet.length > 0 &&
      my_finance_user_in_Json?.wallet[0]?.reserve?.id;

    let walletId =
      my_finance_user_in_Json &&
      my_finance_user_in_Json.wallet &&
      my_finance_user_in_Json.wallet.length > 0 &&
      my_finance_user_in_Json?.wallet[0]?.id;
    console.log("reserveId", reserveId);
    if (reserveId) {
      dispatch(
        getReserveDetailsSlice({
          walletId: walletId,
          moveToNext: () => {},
          onFailure: () => {},
        })
      );
    }
  }, []);

  const getReservePercentage = () => {
    let reserveAmmount = formatNumber(reserveDetails?.reserve_amount || 0);
    let recomendedAmount = formatNumber(
      reserveDetails?.reserve_mode === 1
        ? reserveDetails?.recommended_amount || 0
        : reserveDetails?.reserve_mode === 2
        ? reserveDetails?.manual_specify_amount
        : 0
    );

    console.log("::::::reserveAmmount", reserveDetails);
    console.log("::::::recomendedAmount", typeof recomendedAmount);

    let percentageTotal =
      (parseInt(reserveDetails?.reserve_amount || 0) /
        parseInt(
          reserveDetails?.reserve_mode === 1
            ? reserveDetails?.recommended_amount || 0
            : reserveDetails?.reserve_mode === 2
            ? reserveDetails?.manual_specify_amount
            : 0
        )) *
      100;
    return percentageTotal || 0;
  };

  return (
    <Box
      my={3}
      px={3}
      py={4}
      bgcolor="rgb(31, 34, 39, 0.75)"
      borderRadius="16px"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      {isLoading === true ? (
        <CircularProgress
          sx={{
            color: "#0C7076",
            margin: "auto",
            display: "block",
          }}
        />
      ) : isLoading === false &&
        isError === false &&
        isSuccess === true &&
        reserveDetails ? (
        <>
          {console.log("reserveDetails formula", getReservePercentage())}
          <Box width="140px" height="140px">
            <CircularProgressbarWithChildren
              strokeWidth={2}
              value={getReservePercentage()}
              circleRatio={0.75}
              styles={buildStyles({
                rotation: 1 / 2 + 1 / 8,
                trailColor: "#B5B3BC",
                pathColor: "#0F969C",
              })}
            >
              <div>
                <Box
                  bgcolor="#000"
                  width="45px"
                  height="45px"
                  borderRadius="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  m="auto"
                >
                  <img src={MyReserveIcon} alt="" />
                </Box>
                <Box sx={{ marginTop: "10px" }}>
                  <Typography
                    fontSize="18px"
                    fontWeight="500"
                    variant="h4"
                    textAlign="center"
                    component="h5"
                    color="#fff"
                  >
                    {my_finance_user_in_Json &&
                      my_finance_user_in_Json.wallet &&
                      my_finance_user_in_Json.wallet.length > 0 &&
                      my_finance_user_in_Json?.wallet[0]?.currency?.symbol}
                    {formatNumber(reserveDetails?.reserve_amount || 0)}
                  </Typography>
                  <Typography
                    fontSize="12px"
                    fontWeight="400"
                    variant="h4"
                    textAlign="center"
                    component="h5"
                    color="#B5B3BC"
                  >
                    InReserve
                  </Typography>
                </Box>
              </div>
            </CircularProgressbarWithChildren>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            flexDirection="column"
            height="125px"
          >
            <div style={{ textAlign: "right" }}>
              <Typography
                fontSize="12px"
                component="h5"
                fontWeight="500"
                color="#B5B3BC"
              >
                Available For
              </Typography>
              {/* <Typography
                fontSize="18px"
                component="h6"
                fontWeight="500"
                color="#fff"
              >
                $500
              </Typography> */}
              <Typography
                fontSize="12px"
                component="h6"
                fontWeight="500"
                color="#0F969C"
              >
                {covertDigitIntoMonthsAndDays(
                  reserveDetails?.reserve_amount_available_for_days || 0
                )}
                {/* For 2 Months */}
              </Typography>
            </div>
            <br></br>
            <div style={{ textAlign: "right", display: "flex" }}>
              <img src={RecomendedIcon} alt="" style={{ marginTop: "0px" }} />
              <div style={{ marginLeft: "5px" }}>
                <Typography
                  fontSize="18px"
                  component="h5"
                  fontWeight="500"
                  color="#fff"
                >
                  {my_finance_user_in_Json &&
                    my_finance_user_in_Json.wallet &&
                    my_finance_user_in_Json.wallet.length > 0 &&
                    my_finance_user_in_Json?.wallet[0]?.currency?.symbol}

                  {formatNumber(
                    reserveDetails?.reserve_mode === 1
                      ? reserveDetails?.recommended_amount || 0
                      : reserveDetails?.reserve_mode === 2
                      ? reserveDetails?.manual_specify_amount
                      : 0
                  )}
                </Typography>
                <Typography
                  fontSize="12px"
                  component="h6"
                  fontWeight="500"
                  color="#B5B3BC"
                >
                  Recommended
                </Typography>

                {/* <br></br>
                <Tooltip title="This is calculated on the basis of 6 months reserves">
                  <IconButton
                    style={{
                      position: "relative",
                      top: "-15px",
                      left: "12px",
                    }}
                  >
                    <IconInfoSquareRounded />
                  </IconButton>
                </Tooltip> */}
              </div>
            </div>
          </Box>
        </>
      ) : (
        <FormHelperText
          error
          id="standard-weight-helper-text--register"
          sx={{ marginY: "10px", textAlign: "center" }}
        >
          {message}
        </FormHelperText>
      )}
    </Box>
  );
};

export default ReserveCard;
