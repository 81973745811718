import API from "../../../utilities/API";

export const getPaymentList = async (paymentTypeId) => {
  let url = null;
  if (paymentTypeId) {
    url = `/api/v1/payment?paymentTypeId=${paymentTypeId}`;
  } else {
    url = `/api/v1/payment`;
  }
  const response = await API.get(url);

  return response.data;
};

export const getPaymentDetails = async (paymentId) => {
  const url = `/api/v1/payment/${paymentId}`;
  const response = await API.get(url);

  return response.data;
};

export const creatPayment = async (payload) => {
  const url = "/api/v1/payment";
  const response = await API.post(url, payload);

  return response.data;
};

export const updatePayment = async (paymentId, updatePayload) => {
  const url = `/api/v1/payment/${paymentId}`;
  const response = await API.put(url, updatePayload);

  return response.data;
};

export const deletePayment = async (paymentId) => {
  const url = `/api/v1/payment/${paymentId}`;
  const response = await API.delete(url);

  return response.data;
};
