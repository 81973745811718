import LAYOUT_CONST from "constant";

export const DASHBOARD_PATH = "/custom-dashboard";
export const HORIZONTAL_MAX_ITEM = 7;

const config = {
  layout: LAYOUT_CONST.VERTICAL_LAYOUT, // vertical, horizontal
  drawerType: LAYOUT_CONST.DEFAULT_DRAWER, // default, mini-drawer
  fontFamily: "Nunito Sans",
  borderRadius: 8,
  outlinedFilled: false,
  navType: "dark", // light, dark
  presetColor: "default", // default, theme1, theme2, theme3, theme4, theme5, theme6
  locale: "en", // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
  rtlLayout: false,
  container: false,
};

export const LOG_TYPE = {
  INCOME: 1,
  EXPENSE: 2,
  TRANSFER: 3,
};

export const PAYMENT_TYPE = {
  RECURRING: 1,
  PAY_LATER: 2,
};
export const ACCOUNT_TYPE = {
  CASH: 1,
  SAVING: 2,
};

export const GOAL_TYPE = {
  ACTIVE: 1,
  ACHIVED: 3,
};

export const SOURCE_TYPE = {
  ACCOUNT: 1,
  CREDIT_CARD: 2,
  LOAN: 3,
  GOAL: 4,
  RESERVE: 5,
};

export const RESERVE_TYPE = {
  AUTOMATICALLY: 1,
  MANUAL: 2,
};

export const NOTIFICATION_TYPE = {
  DAILY_REMINDER: 1,
  RESERVE_REMINDER: 2,
  PAYMENT_REMINDER: 3,
  LOAN_REMINDER: 4,
  GOAL_REMINDER: 5,
  BRIEF_REMINDER: 6,
};

export const NOTIFICATION_TYPE_NAME = {
  DAILY_REMINDER: "Daily Logging",
  RESERVE_REMINDER: "MyReserve",
  PAYMENT_REMINDER: "MyPayments",
  LOAN_REMINDER: "Loan",
  GOAL_REMINDER: "Goals",
  BRIEF_REMINDER: "MyBrief",
};

export const CURRENT_APP_VERSION = "1.2.5";

export default config;
