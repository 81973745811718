import dashboard from "./dashboard";
import application from "./application";
import forms from "./forms";
import elements from "./elements";
import samplePage from "./sample-page";
import pages from "./pages";
import utilities from "./utilities";
import support from "./support";
import other from "./other";
import adminDashboard from "./adminDashboard";

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [dashboard],
  adminItems: [adminDashboard],
};

export default menuItems;
