import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getCategoriesList,
  getCategoriesDetails,
  createCategories,
  updateCategories,
  deleteCategories,
} from "../categoriesServices/categoriesServices";

const initialState = {
  singleCategoryDetails: [],
  categoryLists: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const getCategorySlice = createAsyncThunk(
  "categories/getCategoriesList",
  async ({ queryParams, moveToNext, onFailure }, thunkAPI) => {
    try {
      const response = await getCategoriesList(queryParams);
      if (moveToNext) {
        moveToNext(response);
      }
      return response?.data;
    } catch (error) {
      if (onFailure) {
        onFailure(error?.message);
      }

      if (error?.message === "Request failed with status code 403") {
        localStorage.removeItem("serviceToken");
        localStorage.removeItem("my_finance_user");
        window.location.replace("/");
      }

      console.log("error?.message", error?.message);

      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const getCategoriesDetailsSlice = createAsyncThunk(
  "categories/getCategoriesDetails",
  async ({ cardId, moveToNext, onFailure }, thunkAPI) => {
    console.log("cardId", cardId);
    try {
      const response = await getCategoriesDetails(cardId);
      if (moveToNext) {
        moveToNext(response?.data);
      }
      console.log("response?.data", response?.data);
      return response?.data;
    } catch (error) {
      if (onFailure) {
        onFailure(error.message);
      }

      if (error?.message === "Request failed with status code 403") {
        localStorage.removeItem("serviceToken");
        localStorage.removeItem("my_finance_user");
        window.location.replace("/");
      }

      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const createCategoriesSlice = createAsyncThunk(
  "categories/createCategories",
  async ({ payload, moveToNext, onFailure }, thunkAPI) => {
    try {
      const response = await createCategories(payload);
      if (moveToNext) {
        moveToNext(response?.data);
      }
      return response;
    } catch (error) {
      if (onFailure) {
        onFailure(error.message);
      }
      if (error?.message === "Request failed with status code 403") {
        localStorage.removeItem("serviceToken");
        localStorage.removeItem("my_finance_user");
        window.location.replace("/");
      }

      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const updateCategoriesSlice = createAsyncThunk(
  "categories/updateCategories",
  async ({ categoryId, updatePayload, moveToNext, onFailure }, thunkAPI) => {
    try {
      const response = await updateCategories(categoryId, updatePayload);

      if (moveToNext) {
        moveToNext(response?.data);
      }
      return response?.data;
    } catch (error) {
      if (onFailure) {
        onFailure(error.message);
      }

      if (error?.message === "Request failed with status code 403") {
        localStorage.removeItem("serviceToken");
        localStorage.removeItem("my_finance_user");
        window.location.replace("/");
      }

      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const deleteCategoriesSlice = createAsyncThunk(
  "categories/deleteCategories",
  async ({ categoryId, moveToNext, onFailure }, thunkAPI) => {
    try {
      const response = await deleteCategories(categoryId);
      if (moveToNext) {
        moveToNext(response);
      }
      return response;
    } catch (error) {
      if (onFailure) {
        onFailure(error.message);
      }

      if (error?.message === "Request failed with status code 403") {
        localStorage.removeItem("serviceToken");
        localStorage.removeItem("my_finance_user");
        window.location.replace("/");
      }
      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const categorySlice = createSlice({
  name: "categories",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
      state.isLoggedIn = true;
    },
    logoutSlice: (state) => {
      state.isLoginSuccess = false;
      state.isLoggedIn = false;
      state.user = null;
      state.isSuccess = false;
    },
    reset: (state) => {
      state.isLoading = false;
      state.isLoginSuccess = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getCategorySlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(getCategorySlice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.categoryLists = action.payload;
        state.singleCategoryDetails = null;
      })
      .addCase(getCategorySlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action?.payload || "";
      })

      .addCase(getCategoriesDetailsSlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(getCategoriesDetailsSlice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.singleCategoryDetails = action.payload;
      })
      .addCase(getCategoriesDetailsSlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
        state.singleCategoryDetails = null;
      })

      .addCase(createCategoriesSlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(createCategoriesSlice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(createCategoriesSlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(updateCategoriesSlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(updateCategoriesSlice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(updateCategoriesSlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(deleteCategoriesSlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(deleteCategoriesSlice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(deleteCategoriesSlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { setUser, reset, logoutSlice } = categorySlice.actions;

export default categorySlice.reducer;
